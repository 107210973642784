import { Component, OnInit } from '@angular/core';
import { EmailNotificationService } from 'src/app/shared/services/email-notifications.service';
import { AuthService } from './shared/services/auth.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  private interval1: any;
  private interval2: any;

  constructor(
    private emailNotificationsService: EmailNotificationService,
    private authApi: AuthService,
  ) { }

  ngOnInit() {
    // Call the method from the service
    this.emailNotificationsService.checkUnreadMessages();

    // Polling every 1 minute
    // const interval1 = setInterval(() => {
    //   this.emailNotificationsService.checkUnreadMessages();
    // }, 1000); // 1 second in milliseconds

    //Polling every 5 minutes
    const interval2 = setInterval(() => {
      this.emailNotificationsService.checkUnreadMessages();
    }, 5 * 60 * 1000); // 5 minutes in milliseconds
  }

  ngOnDestroy() {
    clearInterval(this.interval1);
    clearInterval(this.interval2);
  }
}
