/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Injectable } from '@angular/core'
import { Agency } from '../models/agency'
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection
} from '@angular/fire/firestore'
import { BehaviorSubject, Observable } from 'rxjs'
import { DefaultText } from '../models/default-text'
import { AngularFireAuth } from '@angular/fire/auth'
import { AngularFireStorage } from '@angular/fire/storage'
import { ToastrService } from 'ngx-toastr'
import { AddOn } from '../models/add-on'
const { clean } = require('rut.js')
import { environment } from 'src/environments/environment'
import axios from 'axios'
import { Reservation } from '../models/reservation'
import { StatePaymentReservation } from '../enums/state-payment.enum'
import { StateReservation } from '../enums/state-reservation.enum'
import { catchError, map, take } from 'rxjs/operators'
import { LogsService } from './logs.service'
import { Book } from '../models/book'
import { Destination } from '../models/destination'
import { HttpClient } from '@angular/common/http'

const _ = require('lodash')

@Injectable({
  providedIn: 'root'
})
export class AgencyService {
  private agencyDoc: AngularFirestoreDocument<Agency>
  private agenciesCollection: AngularFirestoreCollection<Agency>
  agency: Observable<Agency>
  agencies: Observable<Agency[]>

  private destinationDoc: AngularFirestoreDocument<Destination>
  private destinationCollection: AngularFirestoreCollection<Destination>

  private addOnDoc: AngularFirestoreDocument<AddOn>
  private addOnsCollection: AngularFirestoreCollection<AddOn>
  addOn: Observable<AddOn>
  addOns: Observable<AddOn[]>

  private bookCollection: AngularFirestoreCollection<Book>;

  private defaultTextDoc: AngularFirestoreDocument<DefaultText>
  private defaultTextsCollection: AngularFirestoreCollection<DefaultText>
  defaultText: Observable<DefaultText>
  defaultTexts: Observable<DefaultText[]>

  snapshot: Observable<any>
  uploadPercent: Observable<number>
  downloadURL: Observable<string>
  private empresas$: BehaviorSubject<any> = new BehaviorSubject(null)
  constructor(
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
    private afAuth: AngularFireAuth,
    private toastr: ToastrService,
    private logsService: LogsService,
    private http: HttpClient
  ) {
    this.agenciesCollection = firestore.collection<Agency>('agency')
    this.agencies = this.agenciesCollection.valueChanges()
  }

  public getAgencies() {
    return this.firestore
      .collection<Agency>('agency', (ref) =>
        ref.where('isActive', '==', true).orderBy('name', 'asc')
      )
      .valueChanges()
  }
  getAgenciesByText = () =>
    this.firestore.collection<Agency>('agency').snapshotChanges()
  getEmpresas$ = (): Observable<any> => this.empresas$.asObservable()
  getEmpresas = () =>
    this.firestore
      .collection<Agency>('agency', (ref) => ref.where('isActive', '==', true))
      .snapshotChanges()

  public getAgenciesByAgent(agency) {
    try {
      return this.firestore
        .collection<Agency>('agency', (ref) =>
          ref.where('isActive', '==', true).where('key', '==', agency)
        )
        .valueChanges()
    } catch (err) {
      return err
    }
  }

  public getAgency(agencyId) {
    return this.firestore.doc<Agency>('agency/' + agencyId).valueChanges()
  }
  getEmpresaId = (id: string) =>
    this.firestore.collection('agency').doc(id).snapshotChanges()

  public deleteAgency(agencyId) {
    this.agencyDoc = this.firestore.doc<Agency>('agency/' + agencyId)
    const agencyDelete = {
      isActive: false
    }
    this.agencyDoc
      .update(agencyDelete)
      .then((res) => {
        console.log(res)
        return true
      })
      .catch((err) => {
        console.log(err)
        return false
      })
  }

  public addAgency(agency: Agency, logo: any) {
    return new Promise((resolve, reject) => {
      this.agenciesCollection
        .add(agency)
        .then((res) => {
          this.agencyDoc = this.firestore.doc<Agency>('agency/' + res.id)
          agency.key = res.id
          // const randomId = Math.random().toString(36).substring(2);
          const ref = this.storage.ref('agencies/' + res.id)
          const task = ref.put(logo).then((rr) => {
            rr.ref.getDownloadURL().then((refDown) => {
              agency.logo = refDown
              this.agencyDoc.update(agency)
            })
          })

          // Add collections neccesary
          const dummyExp = {
            key: 'string',
            name: 'string',
            description: 'string',
            meetingPoint: 'string',
            included: 'object',
            equipmentRequired: 'object',
            dateInit: 'string',
            dateEnd: 'string',
            dificulty: 'string',
            location: 'string',
            valuePerPerson: 'number',
            principalImage: 'string',
            linkVideo: '',
            weeklySemanal: '',
            periodicity: '',
            duration: 'number',
            createdAt: 'string',
            isActive: false
          }

          this.agencyDoc.collection('experiences').add(dummyExp)
          resolve(true)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  public updateLogo(logo, agency) {
    return new Promise((resolve, reject) => {
      const ref = this.storage.ref('agencies/' + agency.key)

      const task = ref.put(logo).then((rr) => {
        rr.ref.getDownloadURL().then((refDown) => {
          agency.logo = refDown
          this.firestore
            .doc(`agency/${agency.key}`)
            .update({
              logo: refDown,
              "settings.logo": refDown,
            })
            .then(() => {
              resolve(true)
            })
            .then((err) => {
              reject(err)
            })
        })
      })
    })
  }
  public updateBanner(banner, agency) {
    return new Promise((resolve, reject) => {
      const ref = this.storage.ref('agencies/' + agency.key + '/banner')

      const task = ref.put(banner).then((rr) => {
        rr.ref.getDownloadURL().then((refDown) => {
          this.firestore
            .doc(`agency/${agency.key}`)
            .update({
              "settings.banner.url": refDown,
            })
            .then(() => {
              resolve(true)
            })
            .then((err) => {
              reject(err)
            })
        })
      })
    })
  }

  public updateAgency(agency: Agency, key) {
    return new Promise((resolve, reject) => {
      this.agencyDoc = this.firestore.doc<Agency>(`agency/${key}`)
      this.agencyDoc
        .update(agency)
        .then(() => {
          resolve(true)
          this.logsService.dataHistory(key, null, null, null, 'PUT', agency)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  public getDefaultTexts(type: number) {
    return this.firestore
      .collection<DefaultText>('defaultText', (ref) =>
        ref.where('type', '==', type)
      )
      .valueChanges()
  }

  // DESTINATIONS

  public getDestinationsByAgency(agency) {
    return this.firestore
      .collection<Destination>('agency/' + agency + '/destinations'
      )
      .valueChanges()
      .pipe(
        map((destinations: Destination[]) => destinations.map(destination => destination.name))
      );
  }

  public getDestinations(agency) {
    return this.firestore
      .collection<Destination>(
        `agency/${agency}/destinations`
      )
      .valueChanges()
  }

  public addDestination(agencyId, destination: Destination) {
    this.destinationCollection = this.firestore.collection<Destination>(
      `agency/${agencyId}/destinations`
    )

    return new Promise((resolve, reject) => {
      this.destinationCollection
        .add(destination)
        .then((res) => {
          this.destinationDoc = this.firestore.doc<Destination>(
            `agency/${agencyId}/destinations/${res.id}`
          )
          destination.key = res.id
          this.destinationDoc.update(destination)
          resolve('ADD_DESTINATION')
        })
        .catch(() => {
          reject('ERROR_DESTINATION')
        })
    })
  }

  public deleteDestination(agencyId, destination, experienceId?) {
    this.destinationDoc = this.firestore.doc<Destination>(
      'agency/' + agencyId + '/destinations/' + destination.key
    )

    return new Promise((resolve, reject) => {
      this.destinationDoc.valueChanges().pipe(take(1)).subscribe(destination => {
        //Está condicion sirve para el formulario de creacion

        if (destination.experiences.length > 1) {
          //Verificación del destino si es que es usado en más de una experiencia
          reject('DESTINATION_IS_IN_USED')
        } else if (experienceId && destination.experiences.length === 1 && destination.experiences[0] !== experienceId) {
          //Verificación del destino si es que es usado en otra experiencia
          reject('DESTINATION_IS_IN_USED')
        } else if (!experienceId && destination.experiences.length === 1) {
          reject('DESTINATION_IS_IN_USED')
        } else {
          this.destinationDoc
            .delete()
            .then(() => {
              resolve('DESTINATION_REMOVED')
            })
            .catch(error => {
              reject(error)
            });
        }
      })
    })
  }

  public async destinationExperiences(agencyId: string, destinationId: string) {
    const destination = this.firestore.doc<Destination>(
      'agency/' + agencyId + '/destinations/' + destinationId
    ).valueChanges().pipe(take(1));

    return destination;
  }

  // ADDONS
  public getAddons(agency) {
    return this.firestore
      .collection<AddOn>(`agency/${agency}/addons`, (ref) =>
        ref.where('isActive', '==', true)
      )
      .valueChanges()
  }

  public addAddon(agency, addOn: AddOn) {
    this.addOnsCollection = this.firestore.collection<AddOn>(
      `agency/${agency}/addons`
    )
    return new Promise((resolve, reject) => {
      this.addOnsCollection
        .add(addOn)
        .then((res) => {
          this.addOnDoc = this.firestore.doc<AddOn>(
            `agency/${agency}/addons/${res.id}`
          )
          addOn.key = res.id
          this.addOnDoc.update(addOn)
          resolve('ADD_ADDON')
        })
        .catch(() => {
          reject('ERROR_ADDON')
        })
    })
  }

  public deleteAddOn(agencyId, addon) {
    this.addOnDoc = this.firestore.doc<AddOn>(
      'agency/' + agencyId + '/addons/' + addon.key
    )

    const addOnDelete = {
      isActive: false
    }
    this.addOnDoc
      .update(addOnDelete)
      .then(() => {
        return true
      })
      .catch(() => {
        return false
      })
  }

  //SALES
  public getAllSales(data) {
    return new Promise((resolve, reject) => {
      const url = `${environment.urlApiSales}/all`
      axios({
        method: 'POST',
        url,
        data,
        headers: { 'Content-Type': 'application/json' }
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  //SALES BY AGENCY
  public getSalesAgency(data) {
    return new Promise((resolve, reject) => {
      const url = `${environment.urlApiSales}/agency`
      axios({
        method: 'POST',
        url,
        data,
        headers: { 'Content-Type': 'application/json' }
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  public getReservations() {
    return this.firestore
      .collectionGroup<Reservation>('reservation', (ref) =>
        ref
          .where('paymentStatus', '==', StatePaymentReservation.PAYMENT_SUCCESS)
          .where('status', '==', StateReservation.PAID_OUT)
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data()
            const agencyKey = this.getAgencyKey(a.payload.doc.ref.path)
            return { agencyKey, ...data }
          })
        )
      )
  }

  public getReservationsObs() {
    return this.firestore
      .collectionGroup<Reservation>('reservation', (ref) =>
        ref
          .where('paymentStatus', '==', StatePaymentReservation.PAYMENT_SUCCESS)
          .where('status', '==', StateReservation.PAID_OUT)
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data()
            const agencyKey = this.getAgencyKey(a.payload.doc.ref.path)
            const month = data.createdAt.split(' ')[0].split('-')[1]
            const date = new Date(data.createdAt.split(' ')[0])
            const year = data.createdAt.split(' ')[0].split('-')[0]
            return { agencyKey, month, date, year, ...data }
          })
        ),
        map((data) => {
          const total = Object.values(
            data.reduce((acc, { agencyKey, month, year, date, finalPrice }) => {
              finalPrice = +finalPrice // convert to number
              const key = agencyKey + '_' + month + '_' + year // unique combination of id and subject
              this.getAgency(agencyKey)
                .pipe(take(1))
                .subscribe((v) => {
                  if (v.key === agencyKey) {
                    acc[key].name = v.name
                  }
                })
              acc[key] = acc[key] || {
                agencyKey,
                month,
                year,
                date,
                finalPrice: 0
              }
              acc[key].finalPrice += finalPrice
              return acc
            }, {})
          )
          return _.sortBy(
            Object.keys(total).map((id) => total[id]),
            ['date', 'name']
          )
        })
      )
  }
  async getNameAgency(agencyKey) {
    let agencyName = ''
    await this.getAgency(agencyKey)
      .pipe(take(1))
      .subscribe((v) => {
        agencyName = v.name
      })
    return agencyName
  }
  public getAgencyKey(path) {
    const key = path.split('/')[1]
    return key
  }

  public recoveryPass(email) {
    return new Promise((resolve, reject) => {
      this.afAuth.auth
        .sendPasswordResetEmail(email)
        .then(() => {
          resolve(true)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  public isActualUser(email) {
    return new Promise((resolve, reject) => {
      this.afAuth.auth
        .fetchSignInMethodsForEmail(email)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  public isBookExists(agencyId: string) {
    return new Promise<boolean>((resolve, reject) => {
      this.firestore
        .collection('agency/' + agencyId + '/book')
        .get()
        .subscribe(querySnapshot => {
          if (!querySnapshot.empty) {
            resolve(true); // La colección 'book' tiene al menos un documento
          } else {
            resolve(false); // La colección 'book' está vacía
          }
        }, error => {
          reject(error); // Ocurrió un error al obtener la colección
        });
    });
  }

  public addBook(agencyId: string, book: Book): Promise<void> {
    return new Promise((resolve, rejected) => {
      this.firestore.collection<Book>(
        'agency/' + agencyId + '/book'
      )
        .add(book)
        .then((docRef) => {
          docRef.update({ key: docRef.id })
            .then(() => {
              resolve();
            })
            .catch((error) => {
              rejected(error);
            });

        })
        .catch((error) => {
          rejected(error);
        });
    });
  }

  public getBook(agencyId: string) {
    return this.firestore
      .collection<Book>('agency/' + agencyId + '/book')
      .valueChanges()
  }

  public updateBook(agencyId: string, bookId: string, book: Book) {
    return new Promise<void>((resolve, reject) => {
      this.firestore.doc<Book>(
        'agency/' + agencyId + '/book/' + bookId
      )
        .update(book)
        .then(() => {
          resolve(); // Resuelve la promesa si la actualización es exitosa
        })
        .catch((error) => {
          reject(error); // Rechaza la promesa en caso de error
        });
    })
  }

  public addMemberImage(memberImage: any, agencyId: string, index: number) {
    return new Promise((resolve, reject) => {
      const ref = this.storage.ref(`agencies/${agencyId}/agencyMembers/${index}`)

      const task = ref.put(memberImage).then((rr) => {
        rr.ref.getDownloadURL().then((refDown) => {
          resolve(refDown); // Resolvemos con la URL de descarga
        });
      });

    });
  }

  //Consulta a la API web para que ejecute la actualización de los datos de la agencia
  public updateAgencyWebsite(agencyId: string) {
    this.firestore.doc<Agency>('agency/' + agencyId)
      .valueChanges()
      .pipe(
        map((agency: Agency) => agency?.web || '') // Si agency.web no existe, devuelve un string vacío
      ).subscribe((web) => {
        this.http.post(
          web + '/wp-json/heyandes/v1/agency?agency_key=' + agencyId, {}
        ).subscribe(
          () => {
            console.log('Datos de agencia actualizada en la web');
          },
          (error) => {
            console.error('Error en la solicitud HTTP:', error);
          }
        );
      });
  }

  //Consulta a la API web para que ejecute la actualización del cuaderno
  public updateBookWebsite(agencyId: string) {
    this.firestore.doc<Agency>('agency/' + agencyId)
      .valueChanges()
      .pipe(
        map((agency: Agency) => agency?.web || '') // Si agency.web no existe, devuelve un string vacío
      ).subscribe((web) => {
        console.log(web + '/wp-json/heyandes/v1/book?agency_key=' + agencyId);
        this.http.post(
          web + '/wp-json/heyandes/v1/book?agency_key=' + agencyId, {}
        ).subscribe(
          () => {
            console.log('Cuaderno actualizado en la web');
          },
          (error) => {
            console.error('Error en la solicitud HTTP:', error);
          }
        );
      });
  }

  public bookPreview(template: string, colors: any) {
    return this.http.post(
      template + 'wp-json/heyandes/v1/bookpreview', colors
    );
  }

  public sendRackResponse(agencyId: string, userEmail: string, showRack: string) {
    this.firestore.doc<Agency>('agency/' + agencyId)
      .valueChanges()
      .pipe(
        map((agency: Agency) => agency?.web || '') // Si agency.web no existe, devuelve un string vacío
      ).subscribe((web) => {
        this.http.post(
          web + '/wp-json/heyandes/v1/rack?user_email=' + userEmail + '&show_rack=' + showRack, {}
        ).subscribe(
          () => {
            console.log('Respuesta a solicitud flate rate enviada');
          },
          (error) => {
            console.error('Error en la solicitud HTTP:', error);
          }
        );
      });
  }

  public blockTourOperator(agencyId: string, userEmail: string, block: string) {
    this.firestore.doc<Agency>('agency/' + agencyId)
      .valueChanges()
      .pipe(
        map((agency: Agency) => agency?.web || '') // Si agency.web no existe, devuelve un string vacío
      ).subscribe((web) => {
        this.http.post(
          web + '/wp-json/heyandes/v1/block_user?agency_key=' + agencyId + '&user_email=' + userEmail + '&block=' + block, {}
        ).subscribe(
          () => {
            console.log('Respuesta a solicitud flate rate enviada');
          },
          (error) => {
            console.error('Error en la solicitud HTTP:', error);
          }
        );
      });
  }

  //ADD INTEGRATION MERCADOPAGO
  public mercadoPagoIntegration(config, agency) {
    return new Promise((resolve, reject) => {
      this.firestore
        .doc<Agency>('agency/' + agency)
        .update({
          accessTokenMP: config
        })
        .then(() => {
          resolve(true)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}