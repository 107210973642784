export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyA12kF9Mvox5tbSnsVrx0FjXbZK8vvsjt8',
    authDomain: 'heyandes-web.firebaseapp.com',
    databaseURL: 'https://heyandes-web.firebaseio.com',
    projectId: 'heyandes-web',
    storageBucket: 'heyandes-web.appspot.com',
    messagingSenderId: '431968500496',
    appId: '1:431968500496:web:c2a229311a8b077ecd0395',
    measurementId: 'G-24KXN74ZEX'
  },
  urlBooker: 'https://booker-heyandes.web.app',
  urlBookerCopy: 'https://hey-book.com',
  urlFunctions:
    'https://us-central1-heyandes-web.cloudfunctions.net/webApi/api/v1',
  urlAgencyFn:
    'https://us-central1-heyandes-web.cloudfunctions.net/agency/api/v1',
  urlApiPhp: 'https://www.heyandes-app.com/flow-api-prod',
  urlMailing:
    'https://us-central1-heyandes-web.cloudfunctions.net/mailing/api/v1',
  urlAuth: 'https://us-central1-heyandes-web.cloudfunctions.net/auth/api/v1',
  urlFlowPayment:
    'https://us-central1-heyandes-web.cloudfunctions.net/flow/api/v1',
  urlApiSales:
    'https://us-central1-heyandes-web.cloudfunctions.net/sales',
  dashboardUrl: 'https://dashboard-heyandes.web.app',
  bffCalendar: 'https://us-central1-heyandes-web.cloudfunctions.net/bffcalendar',
  helpers: {
    cookies: {
      name: 'com.heyandes'
    }
  },
  gmapsApi:
    'https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyC92E8W5VK-uTuql8ooKti-IyZdlFrQw6s',
  panguipulliKey: 'nKSS5K3LvcMzKSYucOh0',
  urlApiWebpay: 'https://us-central1-heyandes-web.cloudfunctions.net/webpay/api/v1',
  urlMsTransbank: 'https://us-central1-heyandes-web.cloudfunctions.net/ms_transbank/api/v1',
  urlApiCurrency: 'https://api.api-ninjas.com/v1/convertcurrency',
  apiKeyCurrency: '0qU+UxhtmvnIT85ImG3TzA==tGIWx7jNqsTqLglb',
  gmailClientId: '214834274392-jfamn9u1neddkft3gps347e472l3rr37.apps.googleusercontent.com',
  urlAnalitycs: 'https://us-central1-heyandes-web.cloudfunctions.net/analitycs/api/v1',
  wiredApiUrl: 'https://wired.wubook.net/xrws/',
  channelManagerPushNotificationUrl: 'https://us-central1-heyandes-web.cloudfunctions.net/channelManager'
}