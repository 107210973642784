import { Component, AfterViewInit } from "@angular/core";
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig,
} from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "../services/auth.service";
import { Router } from "@angular/router";
import { CookiesService } from "../services/cookies.service";
import { environment } from "src/environments/environment";
import { take, map, tap } from "rxjs/operators";

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
})
export class NavigationComponent implements AfterViewInit {
  public showSearch = false;
  user: any;
  typeUser: any = "";
  logo: any = "";
  nameAgency: any = "";
  nameUser: any = "";
  dataUser: any;
  constructor(
    private modalService: NgbModal,
    private authApi: AuthService,
    public router: Router,
    public coockiesApi: CookiesService
  ) {
    // this.user = JSON.parse(
    //   this.coockiesApi.getCookie(`${environment.helpers.cookies.name}.claims`)
    // );



    // this.getType();
  }

  async ngAfterViewInit() {
    await this.authApi.user$.pipe(take(1)).subscribe((res) => {
      this.typeUser = res.type;
      this.logo =
        res.type !== "SuperAdmin"
          ? res.logoAgency
          : "https://firebasestorage.googleapis.com/v0/b/heyandes-web.appspot.com/o/src%2Flogo-icon-black.png?alt=media&token=5bf20b84-4555-4bb4-839e-58dbc80dafde";
      this.nameAgency = res.nameAgency;
      this.nameUser = res.name;
    });
  }

  async logout() {
    try {
      await this.authApi.logout();
      this.router.navigate(["/auth/login"]);
    } catch (error) {
      console.log(error);
    }
  }

  // async getType() {
  //   this.typeUser = await this.authApi.getRolUser();
  // }
}
