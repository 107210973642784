<nav class="sidebar-nav">
  <ul id="sidebarnav">
    <!-- First level menu -->
    <li
      class="sidebar-item"
      [class.selected]="showMenu === sidebarnavItem.title"
      *ngFor="let sidebarnavItem of sidebarnavItems"
      [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'"
    >
      <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true">
        <i [ngClass]="[sidebarnavItem.icon]"></i
        ><span class="hide-menu">{{ sidebarnavItem.title }}</span>
      </div>
      <a
        class="sidebar-link waves-effect waves-dark"
        [routerLink]="
          sidebarnavItem.class === '' ? [sidebarnavItem.path] : null
        "
        [ngClass]="[sidebarnavItem.class]"
        *ngIf="!sidebarnavItem.extralink"
        (click)="addExpandClass(sidebarnavItem.title)"
        [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'"
      >
        <i [ngClass]="[sidebarnavItem.icon]"></i>
        <span class="hide-menu">{{ sidebarnavItem.title }}</span>
      </a>
    </li>
    <li class="sidebar-item">
      <a rel="preload" href="https://docs.google.com/forms/d/e/1FAIpQLSdYbzD9tjBSjxKGGbih0GI-SYKL4kayrgncs3cV3K5qINESPw/viewform" 
        class="sidebar-link waves-effect waves-dark" target="_blank">
        <i class="mdi mdi-help-circle"></i>
        <span class="hide-menu">Soporte</span>
      </a>
    </li>
  </ul>
</nav>
