import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/agencies',
    title: 'Agencias',
    icon: 'mdi mdi-map',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/agents',
    title: 'Agentes',
    icon: 'mdi mdi-account-multiple',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/settings',
    title: 'Configuración',
    icon: 'mdi mdi-wrench',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/agencies/sales',
    title: 'Recaudaciones',
    icon: 'mdi mdi-chart-pie',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/agencies/sales-webpay',
    title: 'Recaudaciones WP',
    icon: 'mdi mdi-credit-card',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/agencies/settlement',
    title: 'Liquidaciones',
    icon: 'mdi mdi-coin',
    class: '',
    extralink: false,
    submenu: []
  },
  // {
  //   path: '/agencies/finance',
  //   title: 'Finanzas',
  //   icon: 'mdi mdi-chart-areaspline',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  {
    path: '/agencies/calendar',
    title: 'Calendario',
    icon: 'mdi mdi-calendar',
    class: '',
    extralink: false,
    submenu: []
  }
];
