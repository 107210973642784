<ul class="navbar-nav float-left mr-auto align-self-center">
  <li class="nav-item">
    <app-breadcrumb></app-breadcrumb>
  </li>
</ul>
<style>
  small:hover {
    cursor: pointer !important;
  }
</style>
<ul class="navbar-nav float-right">
  <li class="nav-item" placement="bottom-right">
    <!-- <a ngbDropdownToggle class="nav-link dropdown-toggle" href="javascript:void(0)" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <b> <i class="fa fa-user-secret"></i> {{nameUser | titlecase}}</b><br>
        </a> -->
    <div class="row">
      <div class="col-sm-3 align-self-center">
        <img
          *ngIf="logo"
          [src]="logo"
          style="
            border: 1px solid #333;
            object-fit: cover;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 10px;
          "
        />
      </div>
      <div class="col-sm-7 align-self-center text-truncate">
        <b style="text-overflow: ellipsis" style="font-size: 14px">{{
          nameUser | titlecase
        }}</b>
        <p
          style="margin: 0px; padding: 0; margin-top: -8px"
          class="text-truncate"
        >
          <small>
            {{ typeUser !== 'SuperAdmin' ? nameAgency : 'Super Admin' }}
          </small>
        </p>
      </div>
      <div class="col-sm-2 align-self-center">
        <button
          class="btn btn-sm btn-circle btn-outline-light"
          (click)="logout()"
        >
          <i class="fa fa-power-off"></i>
        </button>
      </div>
    </div>
  </li>
</ul>
