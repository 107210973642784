import { RouteInfo } from "./sidebar.metadata";

export const ROUTESAGENTSEC: RouteInfo[] = [
  {
    path: "/experiences/list",
    title: "Experiencias",
    icon: "mdi mdi-heart",
    class: "",
    extralink: false,
    submenu: [],
  },
  {
    path: '/pos',
    title: 'Punto de venta',
    icon: 'mdi mdi-store',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: "/agents",
    title: "Agentes",
    icon: "mdi mdi-account-multiple",
    class: "",
    extralink: false,
    submenu: [],
  },
  {
    path: '/experiences/calendar',
    title: 'Calendario servicios',
    icon: 'mdi mdi-calendar',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/settings',
    title: 'Configuración',
    icon: 'mdi mdi-settings',
    class: '',
    extralink: false,
    submenu: []
  },
];
