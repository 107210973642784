import { Component, AfterViewInit, OnInit } from '@angular/core'
import { ROUTES } from './menu-items'
import { ROUTESAGENT } from './menu-agent-items'
import { ROUTESAGENTSEC } from './menu-agent-sec-items'
import { Router, ActivatedRoute } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { AuthService } from '../services/auth.service'
import { CookiesService } from '../services/cookies.service'
import { take } from 'rxjs/operators'
declare let $: any

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  showMenu = ''
  public isTypeLogin: any = null
  public isSuperAdmin: any = null
  public isAgent: any = null
  public isAgentSecundary: any = null
  public uid: string = null
  public sidebarnavItems: any[]
  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0'
    } else {
      this.showMenu = element
    }
  }
  user: any
  typeUser: any = ''
  logo: any = ''
  nameAgency: any = ''
  nameUser: any = ''
  dataUser: any
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private authApi: AuthService,
    private cookiesApi: CookiesService
  ) {

  }

  // End open close
  async ngOnInit() {
    await this.authApi.user$.pipe(take(1)).subscribe((res) => {
      this.typeUser = res.type
      this.logo =
        res.type !== 'SuperAdmin'
          ? res.logoAgency
          : 'https://firebasestorage.googleapis.com/v0/b/heyandes-web.appspot.com/o/src%2Flogo-icon-black.png?alt=media&token=5bf20b84-4555-4bb4-839e-58dbc80dafde'
      this.nameAgency = res.nameAgency
      this.nameUser = res.name
    })

    await this.authApi.user$.pipe(take(1)).subscribe((res) => {
      switch (res.type) {
        case 'SuperAdmin':
          this.sidebarnavItems = ROUTES.filter(
            (sidebarnavItem) => sidebarnavItem
          )
          break
        case 'Agent':
          this.sidebarnavItems = ROUTESAGENT.filter(
            (sidebarnavItem) => sidebarnavItem
          )
          break
        case 'AgentSecondary':
          this.sidebarnavItems = ROUTESAGENTSEC.filter(
            (sidebarnavItem) => sidebarnavItem
          )
          break
      }
    })
  }

  // getCurrentUser() {
  //   this.authApi.isAuth().subscribe((auth) => {
  //     if (auth) {
  //       this.uid = auth.uid;
  //       this.authApi.isUserAdmin(this.uid).subscribe((userRole) => {
  //         this.isSuperAdmin = Object.assign({}, userRole.roles).hasOwnProperty("superadmin");
  //         if (this.isSuperAdmin) {
  //           this.sidebarnavItems = ROUTES.filter((sidebarnavItem) => sidebarnavItem);
  //         }
  //         this.isAgent = Object.assign({}, userRole.roles).hasOwnProperty("agent");
  //         this.isAgentSecundary = Object.assign({}, userRole.roles).hasOwnProperty(
  //           "agentSecundary"
  //         );
  //         if (this.isAgent || this.isAgentSecundary) {
  //           this.sidebarnavItems = ROUTESAGENCY.filter((sidebarnavItem) => sidebarnavItem);
  //         }
  //       });
  //     }
  //   });

  // if (this.isTypeLogin.superadmin) {

  // } else {
  //   this.sidebarnavItems = ROUTESAGENCY.filter((sidebarnavItem) => sidebarnavItem);
  // }
  // }
}
