import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
@Injectable({
  providedIn: 'root'
})
export class ImportService {
  user: any;
  agency: any;
  constructor(
    private firestore: AngularFirestore,
  ) {

  }

  getAllVendors(agencyId) {
    return this.firestore.collectionGroup('admins', ref => ref.where('agency', '==', agencyId)).valueChanges()
  }

  getAllAddons(agencyId) {
    return this.firestore.collection(`agency/${agencyId}/addons`).valueChanges()
  }

  getAllExperiences(agencyId) {
    return this.firestore.collection(`agency/${agencyId}/experiences`).valueChanges()
  }
}