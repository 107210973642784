import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { take, map, first } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import Axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import * as moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  userLogged: { name: any; email: any; };
  typeUser: string;
  agencyId: string;
  history: any[] = []
  email: FormGroup
  isGmailSettings: any;
  token: string;
  lastMessageId: any[] = []
  threadId: string
  isButtonDisabled: boolean = false;
  constructor(private afs: AngularFirestore) { }

  async getMails(res: any, path: any, passEmail: any, idDeal: any, refreshToken: any) {

    this.userLogged = {
      name: res.name,
      email: res.email
    }

    const threadId = await this.getThreadId(res.agency, idDeal);

    // console.log(threadId)

    this.threadId = threadId
    if (!!threadId) {
      const url = "https://us-central1-heyandes-dev-f736f.cloudfunctions.net/mailtrack/api/mail/read";

      const body = {
        ...passEmail.value,
        threadId: threadId,
        refresh_token: refreshToken
      };

      const config: AxiosRequestConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
        data: body,
      };

      await Axios.post(url, {}, config)
        .then((response: AxiosResponse) => {
          response.data.forEach(async (element) => {

            //logica para los mensajes envidos
            if (!!element.payload.body.decoded) {

              const data = element.payload.headers
              const body = element.payload.body.decoded
              const email = {
                from: '',
                to: '',
                cco: '',
                cc: '',
                body: body,
                subject: '',
                messageId: '',
                references: '',
                inReplyTo: '',
              };

              data.forEach((emailData) => {
                const name = emailData.name.toLowerCase();

                switch (name) {
                  case 'from':
                    email.from = emailData.value;
                    break;
                  case 'to':
                    email.to = emailData.value;
                    break;
                  case 'cc':
                    email.cc = emailData.value;
                    break;
                  case 'cco':
                    email.cco = emailData.value;
                    break;
                  case 'subject':
                    email.subject = emailData.value;
                    passEmail.controls['subject'].setValue(emailData.value);
                    break;
                  case 'message-id':
                    email.messageId = emailData.value;
                    break;
                  case 'references':
                    email.references = emailData.value;
                    break;
                  case 'in-reply-to':
                    email.inReplyTo = emailData.value;
                    break;
                }
              });

              await this.afs
                .collection(`${path}/history`)
                .valueChanges()
                .pipe(
                  first(), // Use the first operator to ensure only the first emission is processed
                  map((emails: any[]) => {
                    const isEmailDuplicate = emails.some(
                      (mail) => mail.messageId === element.id || mail.messageId === email.messageId
                    );
                    return { isEmailDuplicate, emails };
                  })
                )
                .subscribe(async ({ isEmailDuplicate, emails }: { isEmailDuplicate: boolean; emails: any[] }) => {
                  if (isEmailDuplicate) {
                    // Find the document ID of the existing document with the duplicate messageId
                    const existingDocument = emails.find((mail) => mail.messageId === element.id);
                    const existingDocumentId = existingDocument ? existingDocument.documentId : null;

                    // Update the existing document with the new messageId
                    if (existingDocumentId && element.id === threadId) {
                      this.afs
                        .collection(`${path}/history`)
                        .doc(existingDocumentId)
                        .update({
                          messageId: email.messageId,
                        })
                        .then(() => {
                          console.log('Document successfully updated!');
                        })
                        .catch((error) => {
                          console.error('Error updating document: ', error);
                        });
                    } else if (existingDocumentId && element.id !== threadId) {
                      this.afs
                        .collection(`${path}/history`)
                        .doc(existingDocumentId)
                        .update({
                          messageId: email.messageId,
                          references: email.references,
                          inReplyTo: email.inReplyTo,
                        })
                        .then(() => {
                          console.log('Document successfully updated!');
                        })
                        .catch((error) => {
                          console.error('Error updating document: ', error);
                        });
                    }
                  } else {
                    // Add a new document if no duplicate messageId is found
                    this.afs
                      .collection(`${path}/history`)
                      .add({
                        createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
                        user: this.userLogged,
                        action: `de ${email.from} para ${email.to}`,
                        note: email.body,
                        label: email.subject,
                        email: passEmail.value,
                        threadId: element.threadId,
                        messageId: email.messageId,
                        references: email.references,
                        inReplyTo: email.inReplyTo,
                        read: false
                      })
                      .then(() => {
                        console.log('Document successfully added!');
                      })
                      .catch((error) => {
                        console.error('Error adding document: ', error);
                      });
                  }
                });

              // ... Remaining code ...
              // this.afs
              //   .collection(`${path}/history`)
              //   .valueChanges()
              //   .pipe(
              //     map((emails: any[]) => {
              //       const isEmailDuplicate = emails.some(
              //         (mail) => mail.messageId === element.id || mail.messageId === email.messageId
              //       );
              //       return { isEmailDuplicate, emails };
              //     })
              //   )
              //   .subscribe(({ isEmailDuplicate, emails }: { isEmailDuplicate: boolean; emails: any[] }) => {

              //     if (isEmailDuplicate) {
              //       // Find the document ID of the existing document with the duplicate messageId
              //       const existingDocument = emails.find((mail) => mail.messageId === element.id);
              //       const existingDocumentId = existingDocument ? existingDocument.documentId : null;

              //       // Update the existing document with the new messageId
              //       if (existingDocumentId && element.id === threadId) {
              //         this.afs
              //           .collection(`${path}/history`)
              //           .doc(existingDocumentId)
              //           .update({
              //             messageId: email.messageId,
              //           })
              //           .then(() => {
              //             console.log('Document successfully updated!');
              //           })
              //           .catch((error) => {
              //             console.error('Error updating document: ', error);
              //           });
              //       } else if (existingDocumentId && element.id !== threadId) {
              //         this.afs
              //           .collection(`${path}/history`)
              //           .doc(existingDocumentId)
              //           .update({
              //             messageId: email.messageId,
              //             references: email.references,
              //             inReplyTo: email.inReplyTo,
              //           })
              //           .then(() => {
              //             console.log('Document successfully updated!');
              //           })
              //           .catch((error) => {
              //             console.error('Error updating document: ', error);
              //           });
              //       }
              //     } else {
              //       // Add a new document if no duplicate messageId is found
              //       this.afs
              //         .collection(`${path}/history`)
              //         .add({
              //           createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
              //           user: this.userLogged,
              //           action: `de ${email.from} para ${email.to}`,
              //           note: email.body,
              //           label: email.subject,
              //           email: passEmail.value,
              //           threadId: element.threadId,
              //           messageId: email.messageId,
              //           references: email.references,
              //           inReplyTo: email.inReplyTo,
              //           read: false
              //         })
              //         .then(() => {
              //           console.log('Document successfully added!');
              //         })
              //         .catch((error) => {
              //           console.error('Error adding document: ', error);
              //         });
              //     }
              //   });
              // else {
              //   console.log('Se Duplicate email, skipping:', email);
              // }

            } else {

              //logica para mensajes recibidos

              const emailRegex = /<([^>]+)>/; // This regex captures the email within angle brackets
              const data = element.payload.headers
              var body = element.payload.parts[0].body.decoded.split('\r\n')[0];
              const email = {
                from: '',
                to: '',
                cco: '',
                cc: '',
                body: body,
                subject: '',
                messageId: '',
                references: '',
                inReplyTo: '',
              };

              data.forEach((emailData) => {
                const name = emailData.name.toLowerCase(); // Convert name to lowercase for case-insensitivity

                switch (name) {
                  case 'from':
                    email.from = emailData.value.match(emailRegex)[1];
                    break;
                  case 'to':
                    email.to = emailData.value;
                    break;
                  case 'cc':
                    email.cc = emailData.value;
                    break;
                  case 'cco':
                    email.cco = emailData.value;
                    break;
                  case 'subject':
                    email.subject = emailData.value.split(':')[1].trim();
                    break;
                  case 'message-id':
                    email.messageId = emailData.value;
                    break;
                  case 'references':
                    email.references = emailData.value;
                    break;
                  case 'in-reply-to':
                    email.inReplyTo = emailData.value;
                    break;
                }
              });

              await this.afs
                .collection(`${path}/history`)
                .valueChanges()
                .pipe(
                  first(), // Use the first operator to ensure only the first emission is processed
                  map((emails: any[]) => {
                    const isEmailDuplicate = emails.some(
                      (mail) => mail.messageId === element.id || mail.messageId === email.messageId
                    );
                    return { isEmailDuplicate, emails };
                  })
                )
                .subscribe(async ({ isEmailDuplicate, emails }: { isEmailDuplicate: boolean; emails: any[] }) => {
                  if (isEmailDuplicate) {
                    // Find the document ID of the existing document with the duplicate messageId
                    const existingDocument = emails.find((mail) => mail.messageId === element.id);
                    const existingDocumentId = existingDocument ? existingDocument.documentId : null;

                    // Update the existing document with the new messageId
                    if (existingDocumentId && element.id === threadId) {
                      this.afs
                        .collection(`${path}/history`)
                        .doc(existingDocumentId)
                        .update({
                          messageId: email.messageId,
                        })
                        .then(() => {
                          console.log('Document successfully updated!');
                        })
                        .catch((error) => {
                          console.error('Error updating document: ', error);
                        });
                    } else if (existingDocumentId && element.id !== threadId) {
                      this.afs
                        .collection(`${path}/history`)
                        .doc(existingDocumentId)
                        .update({
                          messageId: email.messageId,
                          references: email.references,
                          inReplyTo: email.inReplyTo,
                        })
                        .then(() => {
                          console.log('Document successfully updated!');
                        })
                        .catch((error) => {
                          console.error('Error updating document: ', error);
                        });
                    }
                  } else {
                    // Add a new document if no duplicate messageId is found
                    this.afs
                      .collection(`${path}/history`)
                      .add({
                        createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
                        user: this.userLogged,
                        action: `de ${email.from} para ${email.to}`,
                        note: email.body,
                        label: email.subject,
                        email: passEmail.value,
                        threadId: element.threadId,
                        messageId: email.messageId,
                        references: email.references,
                        inReplyTo: email.inReplyTo,
                        read: false
                      })
                      .then(() => {
                        console.log('Document successfully added!');
                      })
                      .catch((error) => {
                        console.error('Error adding document: ', error);
                      });
                  }
                });
            }
          })
        }).catch(err => {
          console.log(err);
        });

      return { lastMessageId: this.lastMessageId, threadId: this.threadId }
    } else {
      return
    }
  }

  async getThreadId(agencyId: any, idDeal: any): Promise<string | undefined> {
    try {
      const docRef = this.afs.doc(`agency/${agencyId}/funnel/${idDeal}`);

      const docSnapshot = await docRef.get().pipe(take(1)).toPromise();

      if (docSnapshot.exists) {
        const historyCollectionRef = docRef.collection('history');
        const historySnapshot = await historyCollectionRef.get().toPromise();

        // historySnapshot.docs.map((historyDoc) => console.log(historyDoc.data().threadId))
        // Use map to extract threadId values
        const threadIds = historySnapshot.docs.map((historyDoc) => historyDoc.data().threadId);

        const cleanThreadIds = threadIds.filter(element => element !== undefined)

        const lastMessageId = historySnapshot.docs.map((historyDoc) => historyDoc.data().messageId);

        this.lastMessageId = lastMessageId.filter(element => element !== undefined)
        // this.lastMessageId = messageID.length > 0 ? messageID[0] : undefined

        // Assuming you want to return the first threadId (or you can modify this logic)
        return threadIds.length > 0 ? cleanThreadIds[0] : undefined;
      } else {
        console.log('Document does not exist');
        return undefined;
      }
    } catch (error) {
      console.error('Error getting threadId:', error);
      throw error; // Rethrow the error if needed
    }
  }
}

