import { Component, Input, OnInit } from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/storage';
@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent implements OnInit {
  @Input('nameField') nameField: string;
  @Input('nameFolder') nameFolder: string;

  constructor() { }

  ngOnInit(): void {
  }

  uploadFile(event: any) {
    const file = event.target.files[0];
    const storageRef = firebase.storage().ref(this.nameFolder);
    const fileRef = storageRef.child(file.name);
    const task = fileRef.put(file);
    console.log(task)
  }

}
