import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardFinanceComponent } from './dashboard-finance/dashboard-finance.component';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartsModule } from 'ng2-charts';

const routes: Routes = [
  {
    path: 'dashboard',
    pathMatch: 'full',
    // canActivate: [AuthGuard],
    data: {
      title: 'Módulo de finanzas'
    },
    component: DashboardFinanceComponent
  }
]

@NgModule({
  declarations: [
    DashboardFinanceComponent
  ],
  imports: [
    CommonModule, RouterModule.forChild(routes), NgbModule, ChartsModule
  ],
  exports: [
    DashboardFinanceComponent
  ]
})
export class FinanceModule { }

