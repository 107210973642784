<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col-lg-6">
        <button
          routerLink="/agencies/create"
          disabled
          class="btn btn-outline-primary btn-circle btn-sm"
          placement="top"
          ngbTooltip="Listar agencias"
        >
          <em class="fa fa-th-list"></em>
        </button>
      </div>
      <div class="col-lg-6 text-right align-self-center">
        Total por pagar: <strong> ${{ total | number }}</strong>
      </div>
    </div>
  </div>

  <div class="card-body">
    <div class="row">
      <div class="col-lg-5">
        <h3>Reservas por día</h3>
        <div style="display: block">
          <canvas
            *ngIf="!isCharge; else chartOne"
            baseChart
            [datasets]="barChartData"
            [labels]="barChartLabels"
            [options]="barChartOptions"
            [legend]="barChartLegend"
            [chartType]="barChartType"
          >
          </canvas>
          <ng-template #chartOne>
            <h2><em class="fa fa-spin fa-spinner"></em></h2>
          </ng-template>
        </div>
      </div>
      <div class="col-lg-2"></div>
      <div class="col-lg-5">
        <h3>
          Reservas de <strong>{{ mesActual() }}</strong>
        </h3>
        <div style="display: block">
          <canvas
            *ngIf="!isCharge; else chartOne"
            baseChart
            [datasets]="barChartData2"
            [labels]="barChartLabels2"
            [colors]="pieChartColors"
            [options]="barChartOptions2"
            [legend]="barChartLegend2"
            [chartType]="barChartType2"
          >
          </canvas>
          <ng-template #chartOne>
            <h2><em class="fa fa-spin fa-spinner"></em></h2>
          </ng-template>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="card" style="background: #eaeaea; margin-top: 15px">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-4">
                <label><small>Experiencia</small></label>
                <select class="form-control">
                  <option value="">Seleccione una experiencia</option>
                  <option
                    *ngFor="let op of experiences | async"
                    [value]="op.key"
                  >
                    {{ op.name | uppercase }}
                  </option>
                </select>
              </div>
              <div class="col-lg-3">
                <label><small>Desde</small></label>
                <input type="date" [value]="getNow()" class="form-control" />
              </div>
              <div class="col-lg-3">
                <label><small>Hasta</small></label>
                <input
                  type="date"
                  [value]="getTomorrow()"
                  class="form-control"
                />
              </div>
              <div class="align-self-end col-lg-2 mt-1">
                <button class="btn btn-primary btn-block">Filtrar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-hover table-sm table-striped">
        <thead>
          <tr>
            <th class="border-top-0">Experiencia</th>
            <th class="border-top-0">Fecha&nbsp;de&nbsp;pago</th>
            <th class="border-top-0">Fecha&nbsp;reserva</th>
            <th class="border-top-0">Cliente</th>
            <th class="border-top-0">Email</th>
            <th class="border-top-0">Reservas</th>
            <th class="border-top-0">Monto</th>
            <th class="border-top-0">HeyAndes</th>
            <th class="border-top-0">Orden&nbsp;Tbk</th>
            <th class="border-top-0">Acción</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let res of reservations">
            <td class="align-middle">
              <span
                class="text-truncate"
                [ngbTooltip]="res.nameExperience | titlecase"
              >
                {{ res.experience.name | titlecase }}
              </span>
            </td>
            <td class="align-middle">
              {{ res.paymentInfo.transaction_date | date: 'dd-MM-yyyy' }}
            </td>
            <td class="align-middle">
              {{ (res.day | date: 'dd-MM-yyyy') + '/' + res.hour }}
            </td>
            <td class="align-middle">
              <span
                class="text-truncate"
                [ngbTooltip]="res.name + ' ' + res.lastName | titlecase"
              >
                {{ res.name + ' ' + res.lastName | titlecase }}</span
              >
            </td>
            <td class="align-middle">{{ res.email | lowercase }}</td>
            <td class="align-middle">{{ res.persons + res.childs }}</td>
            <td class="align-middle">${{ res.finalPrice | number }}</td>
            <td class="align-middle">
              {{ !!res.heyandes ? res.heyandes.status : 'N/I' }}
            </td>
            <td class="align-middle">
              #{{ res.paymentInfo.authorization_code }}
            </td>
            <td class="align-middle">
              <button
                class="btn btn-outline-primary btn-rounded btn-sm"
                placement="top"
                ngbTooltip="Ver detalle"
                [routerLink]="[
                  '/experiences/reservation/',
                  res.agency,
                  res.experience,
                  res.id,
                  'service'
                ]"
              >
                <em class="fa fa-eye"></em> Ver detalle
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
