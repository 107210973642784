import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ExperienceService } from 'src/app/shared/services/experience.service';
import { FinanceService } from 'src/app/shared/services/finance.service';
import { take } from 'rxjs/operators'
import { AngularFirestore } from '@angular/fire/firestore';
moment.locale('es-CL')
@Component({
  selector: 'app-dashboard-finance',
  templateUrl: './dashboard-finance.component.html',
  styleUrls: ['./dashboard-finance.component.css']
})
export class DashboardFinanceComponent implements OnInit {
  agencyId: any;
  typeUser: any
  agency: any;
  experiences: any;
  days = [];
  reservations = [];
  colors = [];
  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
  };
  public barChartLabels = [];
  public barChartType = 'bar';
  public barChartLegend = true;
  barChartData = [
    { data: [0, 0, 0, 0, 0, 0, 0], label: "Reservas" },
  ];

  public barChartOptions2 = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  public barChartLabels2 = [];
  public barChartType2 = 'pie';
  public barChartLegend2 = true;
  public barChartData2 = [
    { data: [], backgroundColor: [], label: "Experiencias" },
  ];
  public pieChartColors: Array<any> = [{
    backgroundColor: [],
  }];
  isCharge: boolean;
  nameExperiences: any[] = [];
  lastWeek = moment().subtract(1, 'week').startOf('week').format('YYYY-MM-DD HH:mm');
  lastMonth = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm');
  lastThreeMonth = moment().subtract(3, 'month').startOf('month').format('YYYY-MM-DD HH:mm');
  lastSixMonth = moment().subtract(6, 'month').startOf('month').format('YYYY-MM-DD HH:mm');
  currentDate = moment().endOf('month').format('YYYY-MM-DD HH:mm');
  total: number;
  constructor(
    private financeApi: FinanceService,
    private authApi: AuthService,
    private experienceApi: ExperienceService,
    private agencyApi: AgencyService,
    private afs: AngularFirestore
  ) {

    var currentDate = moment();
    var weekStart = currentDate.clone().startOf('week');
    // var weekEnd = currentDate.clone().endOf('week');

    for (let i = 0; i <= 6; i++) {
      this.barChartLabels.push(moment(weekStart).add(i, 'days').format("ddd").toUpperCase());
      this.days.push(moment(weekStart).add(i, 'days').format("yyyy-MM-DD"));
    };
  }



  async ngOnInit() {
    this.isCharge = true;
    this.authApi.user$.pipe(take(1)).subscribe(async (res: any) => {
      this.typeUser = res.type
      this.agencyId = res.agency
      this.agency = this.agencyApi.getAgency(res.agency)
      this.experiences = await this.afs.collection(`agency/${res.agency}/experiences`, ref => ref.where('isActive', '==', true)).valueChanges();
      await this.afs.collection(`agency/${res.agency}/experiences`)
        .get()
        .forEach(resExp => {
          for (let ne of resExp.docs) {
            this.nameExperiences.push({ id: ne.id, ...ne.data() })
            // this.barChartLabels2.push(ne.data().name.toUpperCase())
          }
        })

      await this.afs.collectionGroup('reservations', ref => ref
        .where('agency.key', '==', res.agency)
        .where('paymentStatus', '==', 'Pago realizado')
        .where('status', '==', 'Reserva pagada')
        .orderBy('paymentInfo.transaction_date', 'desc'))
        .get()
        .forEach(response => {
          response.docs.map(r => {
            this.reservations.push({ id: r.id, ...r.data() })
            const rs = this.days.findIndex(day => day === r.data().day);
            if (rs !== -1) {
              this.barChartData[0].data[rs] = this.barChartData[0].data[rs] + r.data().persons
            }
          })
        })
      //CURRENT MONTH
      const startOfMonth = moment().startOf('month').format('YYYY-MM-DD hh:mm');
      const endOfMonth = moment().endOf('month').format('YYYY-MM-DD hh:mm');
      this.total = this.reservations.reduce((tot, sum) => sum.heyandes.status === 'Pendiente de pago' ? tot + sum.finalPrice : tot, 0)
      this.reservations.map(res => {
        if (res.createdAt >= startOfMonth && res.createdAt <= endOfMonth) {
          const ie = this.barChartLabels2.findIndex(r => r === res.nameExperience)
          if (ie === -1) {
            this.barChartLabels2.push(res.nameExperience)
            this.barChartData2[0].data.push(res.persons)
            var randomColor = Math.floor(Math.random() * 16777215).toString(16);
            this.pieChartColors[0].backgroundColor.push('#' + randomColor)
          } else {
            this.barChartData2[0].data[ie] = this.barChartData2[0].data[ie] + res.persons
          }
        }
      })
      this.isCharge = false
    })
  }

  mesActual() {
    return moment().format('MMMM YYYY')
  }

  getNow() {
    return moment().startOf('month').format('YYYY-MM-DD');
  }
  getTomorrow() {
    return moment().endOf('month').format('YYYY-MM-DD');
  }

}
