import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
// import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { tap, map, take } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authApi: AuthService, private router: Router, public toastr: ToastrService) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.authApi.user$.pipe(
      take(1),
      map((user) => user && (this.authApi.isAgent(user) || this.authApi.isAdmin(user))),
      tap((canAgent) => {
        if (!canAgent) {
          this.toastr.warning(
            "No cuentas con el permiso suficiente para ingresar a esta sección",
            "Atención!"
          );
          // this.router.navigateByUrl("/auth/login");
        }
      })
    );
  }
}
