import { RouteInfo } from './sidebar.metadata'

export const ROUTESAGENT: RouteInfo[] = [
  // {
  //   path: '/sales/company',
  //   title: 'Ventas',
  //   icon: 'mdi mdi-chart-pie',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/sales/settlement',
  //   title: 'Liquidaciones',
  //   icon: 'mdi mdi-animation',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  {
    path: '/experiences/list',
    title: 'Experiencias',
    icon: 'mdi mdi-heart',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/experiences/services/blockers',
    title: 'Blockers',
    icon: 'mdi mdi-lock',
    class: '',
    extralink: false,
    submenu: []
  },
  // {
  //   path: '/sales/list',
  //   title: 'Finanzas',
  //   icon: 'mdi mdi-calculator',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  {
    path: '/agents',
    title: 'Agentes',
    icon: 'mdi mdi-account-multiple',
    class: '',
    extralink: false,
    submenu: []
  },

  {
    path: '/contacts',
    title: 'Solicitudes',
    icon: 'mdi mdi-email',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/pos',
    title: 'Punto de venta',
    icon: 'mdi mdi-store',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/experiences/calendar',
    title: 'Calendario servicios',
    icon: 'mdi mdi-calendar',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/resources',
    title: 'Recursos',
    icon: 'mdi mdi-animation',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/statistics',
    title: 'Estadísticas',
    icon: 'mdi mdi-chart-bar',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/settings',
    title: 'Configuración',
    icon: 'mdi mdi-settings',
    class: '',
    extralink: false,
    submenu: []
  },
  // {
  //   path: '/flow',
  //   title: 'Pagos Flow',
  //   icon: 'mdi mdi-credit-card',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // }
  {
    path: '/marketing/list',
    title: 'Módulo de marketing',
    icon: 'mdi mdi-flag-variant',
    class: '',
    extralink: false,
    submenu: []
  },
  // {
  //   path: '/channelManager',
  //   title: 'Channel Manager',
  //   icon: 'mdi mdi-lan',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // }
]
