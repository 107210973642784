import { Routes, ExtraOptions} from '@angular/router'
import { FullComponent } from './layouts/full/full.component'

export const Approutes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule)
  },
  {
    path: '',
    component: FullComponent,
    children: [
      { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
      {
        path: 'marketplace',
        loadChildren: () =>
          import('./agencies/agencies.module').then((m) => m.AgenciesModule)
      },
      {
        path: 'agencies',
        loadChildren: () =>
          import('./agencies/agencies.module').then((m) => m.AgenciesModule)
      },
      {
        path: 'experiences',
        loadChildren: () =>
          import('./experiences/experiences.module').then(
            (m) => m.ExperiencesModule
          )
      },
      {
        path: 'agents',
        loadChildren: () =>
          import('./agents/agents.module').then((m) => m.AgentsModule)
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./settings/settings.module').then((m) => m.SettingsModule)
      },
      {
        path: 'collections',
        loadChildren: () =>
          import('./collections/collections.module').then(
            (m) => m.CollectionsModule
          )
      },
      {
        path: 'component',
        loadChildren: () =>
          import('./component/component.module').then((m) => m.ComponentsModule)
      },
      {
        path: 'pos',
        loadChildren: () => import('./pos/pos.module').then((m) => m.PosModule)
      },
      {
        path: 'resources',
        loadChildren: () => import('./resources/resources.module').then((m) => m.ResourcesModule)
      },
      {
        path: 'marketing',
        loadChildren: () =>
          import('./marketing/marketing.module').then((m) => m.MarketingModule)
      },
      {
        path: 'contacts',
        loadChildren: () =>
          import('./contacts/contacts.module').then((m) => m.ContactsModule)
      },
      {
        path: 'finance',
        loadChildren: () =>
          import('./finance/finance.module').then((m) => m.FinanceModule)
      },
      {
        path: 'sales',
        loadChildren: () =>
          import('./sales/sales.module').then((m) => m.SalesModule)
      },
      {
        path: 'statistics',
        loadChildren: () => import('./statistics/statistics.module').then((m) => m.StatisticModule)
      },
      {
        path: 'channelManager',
        loadChildren: () => import('./channel-manager/channel-manager.module').then((m) => m.ChannelManagerModule)
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'auth'
  }
]

export const RouterOptions: ExtraOptions = {
  useHash: false,
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled'
}