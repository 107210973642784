import { Injectable } from '@angular/core';
import {
  AngularFirestoreDocument,
  AngularFirestoreCollection,
  AngularFirestore
} from '@angular/fire/firestore'

@Injectable({
  providedIn: 'root'
})
export class FinanceService {

  constructor(
    private firestore: AngularFirestore,
  ) { }

  getAllExperiencesById = (agencyId) => {
    const response = this.firestore.collection(`agency/${agencyId}/experiences`).get();
    const experiences = [];
    // response.forEach((e) => {
    //   e.docs.forEach(exp => {
    //     experiences.push({ id: exp.id, ...exp.data() })
    //   })
    // })
    response.subscribe(ex => {
      for (let exp of ex.docs) {
        experiences.push({ id: exp.id, ...exp.data() })
      }
      return experiences;
    })
  }


}
