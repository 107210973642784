import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CookiesService {
  constructor() {}

  getCookie(cname) {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  deleteCookie(name) {
    this.setCookie(name, "", -1);
    // document.cookie = + name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }

  setStorage(cname, cvalue) {
    window.localStorage.setItem(cname, cvalue);
  }

  getStorage(cname) {
    return window.localStorage.getItem(cname);
  }

  clearStorage(cname) {
    // return window.localStorage.getItem(cname);
    return window.localStorage.removeItem(cname);
  }
}
