import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  CommonModule,
  LocationStrategy,
  HashLocationStrategy
} from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FullComponent } from './layouts/full/full.component';

import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';

import { Approutes, RouterOptions} from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';

import { ToastrModule } from 'ngx-toastr';
import { AuthGuard } from './shared/guards/auth.guard';
import { AgentSecondaryGuard } from './shared/guards/agent-secondary.guard';
import { AdminGuard } from './shared/guards/admin.guard';
import { ExcelService } from './shared/services/excel.service';
import { ImportService } from './shared/services/import.service';
import { FinanceModule } from './finance/finance.module';
import { UploadFileComponent } from './shared/upload-file/upload-file.component';
import { NgxImageCompressService } from 'ngx-image-compress';
import { EmailNotificationService } from './shared/services/email-notifications.service'
import { FormBuilder, } from '@angular/forms';
@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    UploadFileComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'My-XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN'
    }),
    NgbModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    AngularFireAuthModule,
    RouterModule.forRoot(Approutes, RouterOptions),
    ToastrModule.forRoot(),
    FinanceModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    AngularFirestore,
    AuthGuard,
    AdminGuard,
    AgentSecondaryGuard,
    ExcelService,
    ImportService,
    NgxImageCompressService,
    EmailNotificationService,
    FormBuilder
  ],
  exports: [
    UploadFileComponent,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
