// email-notification.service.ts

import { Injectable } from '@angular/core';
import { EmailService } from './email.service';
import { AuthService } from './auth.service';
import { take, map } from 'rxjs/operators';
import { ActivatedRoute, Params } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr'

@Injectable({
  providedIn: 'root',
})
export class EmailNotificationService {
  isGmailSettings: any;
  token: string;
  refreshToken: string;
  idDeal: string
  email: FormGroup
  res: any
  lastMessageId: any = []
  constructor(
    private emailService: EmailService,
    private authApi: AuthService,
    private route: ActivatedRoute,
    private afs: AngularFirestore,
    private fb: FormBuilder,
    private toastr: ToastrService

  ) {
    this.email = this.fb.group({
      from: ['', [Validators.required]],
      to: ['', [Validators.required, this.emailListValidator()]],
      cco: [''],
      cc: [''],
      body: ['', [Validators.required]],
      subject: ['', [Validators.required]],
    })
  }

  async checkUnreadMessages() {
    const user = await this.authApi.user$.pipe(take(1)).toPromise();

    if (!user) {
      return
    }
    this.email.controls['from'].setValue(user.email.toLowerCase());

    const funnelQuerySnapshot = await this.afs.collection(`agency/${user.agency}/funnel`).get().toPromise();

    const promises = [];

    for (const doc of funnelQuerySnapshot.docs) {
      this.idDeal = doc.id;
      const splitMail = user.email.split('@');
      this.email.controls['cc'].setValue(`${splitMail[0]}+${this.idDeal}@${splitMail[1]}`);

      this.res = { id: doc.id, ...doc.data(), path: doc.ref.path };
      this.email.get('to').setValue(this.res.contact.email.toLowerCase());

      promises.push(
        this.getIfExist(user.agency),
        this.emailService.getMails(user, this.res.path, this.email, this.idDeal, this.refreshToken)
      );
    }

    // Wait for all promises to resolve
    await Promise.all(promises).then(async () => {
      // After promises are resolved, check unread messages
      await this.checkUnreadMessagesSpecificLogic(funnelQuerySnapshot);
    })
  }

  async checkUnreadMessagesSpecificLogic(funnelQuerySnapshot) {
    for (const doc of funnelQuerySnapshot.docs) {
      const historyRef = doc.ref.collection('history');
      const unreadMessagesQuery = historyRef.where('read', '==', false);
      const unreadMessagesSnapshot = await unreadMessagesQuery.get();

      if (!unreadMessagesSnapshot.empty) {
        this.toastr.warning('Tienes mensajes sin leer en Funnel', 'Alerta!')
      }
    }
  }

  async getIfExist(agency) {
    await this.afs.collection(`agency/${agency}/settings_gmail`)
      .get()
      .forEach(docs => {
        this.isGmailSettings = docs.empty
        this.token = docs.docs[0]?.data()?.access_token;
        this.refreshToken = docs.docs[0]?.data()?.refresh_token;
      })
  }

  emailListValidator() {
    return (control: FormGroup) => {
      const emails = control.value.split(',');
      const emailRegex = /\S+@\S+\.\S+/;

      for (let i = 0; i < emails.length; i++) {
        if (!emailRegex.test(emails[i].trim())) {
          return { invalidEmail: true };
        }
      }

      return null;
    };
  }
}
