import { AdminAgency } from '../models/admin-agency';

export class RoleValidator {
  isAgent(user: AdminAgency): boolean {
    return user.type === 'Agent';
  }

  isAgentSecondary(user: AdminAgency): boolean {
    console.log('user->', user);

    return user.type === 'AgentSecondary';
  }

  isAdmin(user: AdminAgency): boolean {
    return user.type === 'SuperAdmin';
  }
}
