/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import {
  AngularFirestoreDocument,
  AngularFirestoreCollection,
  AngularFirestore
} from '@angular/fire/firestore'
import { Experience } from '../models/experience'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { map, take, first, catchError, tap } from 'rxjs/operators'
import { AgencyService } from './agency.service'
import { ScheduleExperience } from '../models/schedule-experience'
import { Gallery } from '../models/gallery'
import { AngularFireStorage } from '@angular/fire/storage'
import { rejects } from 'assert'
import { resolve } from 'dns'
import { Service } from '../models/service'
import { Reservation } from '../models/reservation'
import { StatePaymentReservation } from '../enums/state-payment.enum'
import { State } from '../enums/state.enum'
import { StateReservation } from '../enums/state-reservation.enum'
import { Restaurant } from '../models/restaurant'
import { Hotel } from '../models/hotel'
import { Ecommerce } from '../models/ecommerce'
import { LogsService } from './logs.service'
import axios from 'axios'
import { environment } from 'src/environments/environment'
import Slug from './../helpers/converToSlug'
import { ignoreElements } from 'rxjs-compat/operator/ignoreElements'

import { Agency } from '../models/agency'
import { Destination } from '../models/destination'

@Injectable({
  providedIn: 'root'
})
export class ExperienceService {
  private experienceDoc: AngularFirestoreDocument<Experience>
  private experiencesCollection: AngularFirestoreCollection<Experience>
  experience: Observable<Experience>
  experiences: Observable<Experience[]>

  private experienceRestaurantDoc: AngularFirestoreDocument<Restaurant>
  private experiencesRestaurantCollection: AngularFirestoreCollection<Restaurant>
  experienceRestaurant: Observable<Restaurant>
  experiencesRestaurant: Observable<Restaurant[]>

  private experienceEcommerceDoc: AngularFirestoreDocument<Ecommerce>
  private experiencesEcommerceCollection: AngularFirestoreCollection<Ecommerce>
  experienceEcommerce: Observable<Ecommerce>
  experiencesEcommerce: Observable<Ecommerce[]>

  private experienceHotelDoc: AngularFirestoreDocument<Hotel>
  private experiencesHotelCollection: AngularFirestoreCollection<Hotel>
  experienceHotel: Observable<Hotel>
  experiencesHotel: Observable<Hotel[]>

  private destinationDoc: AngularFirestoreDocument<Destination>
  private destinationDoc2: AngularFirestoreDocument<Destination>
  private destinationCollection: AngularFirestoreCollection<Destination>


  private scheduleDoc: AngularFirestoreDocument<ScheduleExperience>
  private scheduleExperiencesCollection: AngularFirestoreCollection<ScheduleExperience>

  private galleryDoc: AngularFirestoreDocument<Gallery>
  private galleriesCollection: AngularFirestoreCollection<Gallery>

  agency: Observable<any>
  experienceDet: Observable<any>
  schedule: Observable<any>
  gallery: Observable<any>

  private reservations$: BehaviorSubject<any> = new BehaviorSubject(null)

  constructor(
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
    private logsService: LogsService,

    private http: HttpClient
  ) {
    this.experiencesCollection = firestore.collection<Experience>('experience')
    this.experiences = this.experiencesCollection.valueChanges()
  }

  public async getAddessInfo(data) {
    return await axios({
      method: 'GET',
      url: `${environment.gmapsApi}&latlng=${data}`,
      headers: { 'Content-Type': 'application/json' }
    })
  }

  setReservations$ = (items: any) => this.reservations$.next(items)
  /** TODO : LIST SERVICES */
  public getAllReservationObs = () =>
    this.firestore
      .collectionGroup<Reservation>('reservation', (ref) =>
        ref
          .where('paymentStatus', '==', StatePaymentReservation.PAYMENT_SUCCESS)
          .where('status', '==', StateReservation.PAID_OUT)
      )
      .snapshotChanges()

  public getAllServiceObs = () =>
    this.firestore.collectionGroup<Reservation>('services').snapshotChanges()

  public getKeyService = (params) => {
    if (params.day && params.hour) {
      return this.firestore
        .collection<Service>(
          `/agency/${params.agencyKey}/experiences/${params.experience}/services`,
          (ref) =>
            ref.where('day', '==', params.day).where('hour', '==', params.hour)
        )
        .snapshotChanges()
    }
  }
  /** END LIST SERVICES */
  public getAllExpercies(agencyId: string) {
    return this.firestore
      .collection<Experience>('agency/' + agencyId + '/experiences')
      .valueChanges()
  }
  public getExperiencesByAgency(agencyId: string) {
    return this.firestore
      .collection<Experience>('agency/' + agencyId + '/experiences', (ref) =>
        ref.where('isActive', '==', true)
      )
      .valueChanges()
  }

  public getExperiencesByAgencys(agencyId: string) {
    return this.firestore
      .collection<Experience>('agency/' + agencyId + '/experiences', (ref) =>
        ref.where('isActive', '==', true)
      )
      .get()
  }

  public getExperienceByAgency(agencyId: string, experienceId: string) {
    return this.firestore
      .doc<Experience>('agency/' + agencyId + '/experiences/' + experienceId)
      .valueChanges()
  }

  public getScheduleByExperience(agencyId: string, experienceId: string) {
    return this.firestore
      .collection<Experience>(
        'agency/' + agencyId + '/experiences/' + experienceId + '/schedule',
        (ref) => ref.where('isActive', '==', true)
      )
      .valueChanges()
  }

  public async getGalleryByExperience(agencyId: string, experienceId: string) {
    const gallery = await this.firestore
      .collection<Experience>(
        'agency/' + agencyId + '/experiences/' + experienceId + '/gallery',
        (ref) => ref.where('isActive', '==', true)
      )
      .get()
      .pipe(take(1))
      .toPromise();

    if (gallery.docs.length === 0) {
      for (let i = 0; i < 6; i++) {
        this.addEmptyImagesForEdit(agencyId, experienceId);
      }
    }
    else if (gallery.docs.length === 3) {
      for (let i = 0; i < 3; i++) {
        this.addEmptyImagesForEdit(agencyId, experienceId);
      }
    }

    return this.firestore
      .collection<Experience>(
        'agency/' + agencyId + '/experiences/' + experienceId + '/gallery',
        (ref) => ref.where('isActive', '==', true)
      )
      .valueChanges()

  }

  public getExperience(agencyId: string, experienceId: string) {
    return this.firestore
      .collection<Experience>('agency/' + agencyId + '/experiences/', (ref) =>
        ref.where('isActive', '==', true)
      )
      .doc(experienceId)
      .valueChanges()
  }

  public deleteExperience(agencyId: string, experienceId: string) {
    return new Promise((resolve, reject) => {
      this.experienceDoc = this.firestore.doc<Experience>(
        'agency/' + agencyId + '/experiences/' + experienceId
      )
      const experienceDelete = {
        isActive: false
      }
      this.firestore
        .collection('agency/')
        .doc(agencyId)
        .get()
        .toPromise()
        .then((agencyRes) => {
          this.experienceDoc
            .get()
            .toPromise()
            .then((expRes) => {
              this.experienceDoc
                .update(experienceDelete)
                .then(() => {
                  resolve(true)
                  this.logsService.dataHistory(
                    agencyId,
                    expRes.data(),
                    experienceId,
                    null,
                    'DELETE',
                    agencyRes.data()
                  )
                })
                .catch((err) => {
                  reject(err)
                })
            })
        })
    })
  }

  public async verifyServicesConfirmed(agencyId: string, experienceId: string) {
    return await this.firestore
      .collection<Service>(
        'agency/' + agencyId + '/experiences/' + experienceId + '/services',
        (ref) => ref.where('isConfirmed', '==', true)
      )
      .valueChanges()
  }

  public deleteImage(
    agencyId: string,
    experienceId: string,
    image: { key: string },
    isPrincipalImage: any
  ) {
    return new Promise((resolve, rejected) => {
      this.firestore
        .doc<Gallery>(
          'agency/' +
          agencyId +
          '/experiences/' +
          experienceId +
          '/gallery/' +
          image.key
        )
        .delete()
        .then((res) => {
          // console.log(res, 'delete')
          if (isPrincipalImage) {
            this.experienceDoc = this.firestore.doc<Experience>(
              'agency/' + agencyId + '/experiences/' + experienceId
            )
            const experience = {
              principalImage: null
            }
            this.experienceDoc.update(experience)
            resolve(res)
          } else {
            resolve(res)
          }
        })
        .catch((err) => {
          rejected(err)
        })
    })
  }

  public updateImage(
    agencyId: string,
    experienceId: string,
    image: { key: string; isPrincipalImage: any; path?: string },
    fileUpload: any
  ) {
    return new Promise((resolve, reject) => {
      //Delete actual image
      this.galleryDoc = this.firestore.doc<Gallery>(
        'agency/' +
        agencyId +
        '/experiences/' +
        experienceId +
        '/gallery/' +
        image.key
      )
      //Update new image
      const randomId = Math.random().toString(36).substring(2)
      const ref = this.storage.ref(
        'agencies/' +
        agencyId +
        '/experiences/' +
        experienceId +
        '/gallery/' +
        randomId
      )
      if (fileUpload) {
        //Add Image 1
        ref.put(fileUpload).then((rr) => {
          rr.ref.getDownloadURL().then((refDown) => {
            //Update isPrincipalImage
            if (image.isPrincipalImage) {
              this.experienceDoc = this.firestore.doc<Experience>(
                'agency/' + agencyId + '/experiences/' + experienceId
              )
              const experience = {
                principalImage: refDown
              }
              this.experienceDoc.update(experience)
            }

            //Update key experience
            this.galleryDoc
              .update({ image: refDown, path: randomId })
              .then((res) => {
                resolve(res)
              })
              .catch((err) => {
                reject(err)
              })
          })
        })
      } else {
        //eliminar imagen y actualizar firestore
        // console.log('eliminar')
        const refStorage = this.storage.ref(
          'agencies/' +
          agencyId +
          '/experiences/' +
          experienceId +
          '/gallery/' +
          image.path
        )

        refStorage.delete().subscribe((res) => {
          // console.log(res, 'eliminado con exito')
          this.galleryDoc
            .update({ image: '', path: '' })
            .then((res) => {
              resolve(res)
            })
            .catch((err) => {
              reject(err)
            })
        })
      }
    })
  }

  public addEmptyImagesForEdit(
    agencyId: string,
    experienceId: string,
    isPrincipalImage?: boolean
  ) {
    return new Promise((resolve, rejected) => {
      const addImage = {
        key: null,
        image: '',
        isActive: true,
        path: ''
      }

      if (isPrincipalImage) addImage['isPrincipalImage'] = true

      this.galleriesCollection = this.firestore.collection<Gallery>(
        'agency/' + agencyId + '/experiences/' + experienceId + '/gallery'
      )

      this.galleriesCollection.add(addImage).then((gal) => {
        this.galleryDoc = this.firestore.doc<Gallery>(
          'agency/' +
          agencyId +
          '/experiences/' +
          experienceId +
          '/gallery/' +
          gal.id
        )
        addImage.key = gal.id
        addImage.path = ''
        this.galleryDoc.update(addImage)
      })
    })
  }

  private getDestinationDoc(agencyId: string, destinationId: string) {
    return this.firestore.doc<Destination>(
      'agency/' + agencyId + '/destinations/' + destinationId
    ).snapshotChanges().pipe(take(1));
  }

  public addKeyToDestinations(
    agencyId: string,
    destinationId: string,
    experienceId: string
  ) {
    return new Promise((resolve, reject) => {
      this.getDestinationDoc(agencyId, destinationId).subscribe(action => {
        const destinationRef = action.payload.ref;
        const destination = action.payload.data() as any;

        if (!destination.experiences) {
          destination.experiences = [];
        }

        if (!destination.experiences.includes(experienceId)) {
          destination.experiences = [...destination.experiences, experienceId];
          destinationRef.update(destination)
            .then(() => {
              // console.log("Destino agregado");
              // console.log(destination);
              resolve('KEY_ADDED')
            })
            .catch(() => {
              reject('KEY_ERROR')
            });
        }
      });
    });
  }

  public removeKeyToDestinations(
    agencyId: string,
    destinationId: string,
    experienceId: string
  ) {
    return new Promise((resolve, reject) => {
      this.getDestinationDoc(agencyId, destinationId).subscribe(action => {
        const destinationRef = action.payload.ref;
        const destination = action.payload.data() as any;
        destination.experiences = destination.experiences.filter(expKeys => expKeys != experienceId)
        destinationRef.update(destination)
          .then(() => {
            // console.log("Destino borrado");
            // console.log(destination)
            resolve("DESTINATION_REMOVED")
          })
          .catch(error => {
            console.error("Error", error);
            reject("DESTINATION_ERROR")
          });
      });
    })
  }

  public addExperience(
    agencyId: string,
    experience: Experience,
    schedule: ScheduleExperience,
    gallery: { principalImage: any; imageTwo: any; imageThree: any; imageFour: any; imageFive: any; imageSix: any },
    destinationId?: string
  ) {
    return new Promise((resolve, rejected) => {
      this.firestore.collection<Experience>(
        'agency/' + agencyId + '/experiences'
      )
        .add(experience)
        .then((res) => {
          this.experienceDoc = this.firestore.doc<Experience>(
            'agency/' + agencyId + '/experiences/' + res.id
          )
          experience.key = res.id

          this.experienceDoc.update({ key: res.id })

          if (experience.key) {
            if (destinationId) {
              this.addKeyToDestinations(agencyId, destinationId, experience.key)
            }

            this.createExperienceWebsite(agencyId, experience.key);

            this.scheduleExperiencesCollection =
              this.firestore.collection<ScheduleExperience>(
                'agency/' + agencyId + '/experiences/' + res.id + '/schedule'
              )

            this.galleriesCollection = this.firestore.collection<Gallery>(
              'agency/' + agencyId + '/experiences/' + res.id + '/gallery'
            )

            //Add schedule to experience created

            this.scheduleExperiencesCollection.add(schedule).then((sc) => {
              this.scheduleDoc = this.firestore.doc<ScheduleExperience>(
                'agency/' +
                agencyId +
                '/experiences/' +
                res.id +
                '/schedule/' +
                sc.id
              )
              schedule.key = sc.id
              //Update key shcedule
              this.scheduleDoc.update(schedule).then(() => {
                resolve(true)
              })
            })

            //Add Gallery to experience

            //Ref Image 1 & principalImage
            const randomId = Math.random().toString(36).substring(2)
            const ref = this.storage.ref(
              'agencies/' +
              agencyId +
              '/experiences/' +
              res.id +
              '/gallery/' +
              randomId
            )

            //Add Image 1 & principalImage
            ref.put(gallery.principalImage).then((rr) => {
              rr.ref.getDownloadURL().then((refDown) => {
                experience.principalImage = refDown
                //Update key experience
                this.experienceDoc.update(experience)
                const galleryAdd = {
                  key: null,
                  image: null,
                  isActive: true,
                  isPrincipalImage: true,
                  path: null
                }
                galleryAdd.image = refDown
                galleryAdd.isActive = true
                galleryAdd.path = randomId
                galleryAdd.isPrincipalImage = true

                this.galleriesCollection.add(galleryAdd).then((gal) => {
                  this.galleryDoc = this.firestore.doc<Gallery>(
                    'agency/' +
                    agencyId +
                    '/experiences/' +
                    res.id +
                    '/gallery/' +
                    gal.id
                  )
                  galleryAdd.key = gal.id
                  this.galleryDoc.update(galleryAdd)
                })
              })
            })

            this.firestore
              .collection('agency/')
              .doc(agencyId)
              .get()
              .toPromise()
              .then((agencyRes) => {
                this.logsService.dataHistory(
                  agencyId,
                  experience,
                  experience.key,
                  null,
                  'POST',
                  agencyRes.data()
                )
              })

            //Add Image 2 & 3 if exist
            if (gallery.imageTwo) {
              const randomId = Math.random().toString(36).substring(2)
              //Ref Gallery
              const refGallery = this.storage.ref(
                'agencies/' +
                agencyId +
                '/experiences/' +
                res.id +
                '/gallery/' +
                randomId
              )
              refGallery.put(gallery.imageTwo).then((rr) => {
                rr.ref.getDownloadURL().then((refDown) => {
                  const addImage = {
                    key: null,
                    image: refDown,
                    isActive: true,
                    path: null
                  }
                  this.galleriesCollection.add(addImage).then((gal2) => {
                    this.galleryDoc = this.firestore.doc<Gallery>(
                      'agency/' +
                      agencyId +
                      '/experiences/' +
                      res.id +
                      '/gallery/' +
                      gal2.id
                    )
                    addImage.key = gal2.id
                    addImage.path = randomId
                    this.galleryDoc.update(addImage)
                  })
                })
              })
            } else {
              const addImage = {
                key: null,
                image: '',
                isActive: true,
                path: ''
              }
              this.galleriesCollection.add(addImage).then((gal) => {
                this.galleryDoc = this.firestore.doc<Gallery>(
                  'agency/' +
                  agencyId +
                  '/experiences/' +
                  res.id +
                  '/gallery/' +
                  gal.id
                )
                addImage.key = gal.id
                addImage.path = ''
                this.galleryDoc.update(addImage)
              })
            }

            if (gallery.imageThree) {
              const randomId = Math.random().toString(36).substring(2)
              //Ref Gallery
              const refGallery = this.storage.ref(
                'agencies/' +
                agencyId +
                '/experiences/' +
                res.id +
                '/gallery/' +
                randomId
              )
              refGallery.put(gallery.imageThree).then((rr) => {
                rr.ref.getDownloadURL().then((refDown) => {
                  const addImage = {
                    key: null,
                    image: refDown,
                    isActive: true,
                    path: null
                  }
                  this.galleriesCollection.add(addImage).then((gal3) => {
                    this.galleryDoc = this.firestore.doc<Gallery>(
                      'agency/' +
                      agencyId +
                      '/experiences/' +
                      res.id +
                      '/gallery/' +
                      gal3.id
                    )
                    addImage.key = gal3.id
                    addImage.path = randomId
                    this.galleryDoc.update(addImage)
                  })
                })
              })
            } else {
              const addImage = {
                key: null,
                image: '',
                isActive: true,
                path: ''
              }
              this.galleriesCollection.add(addImage).then((gal) => {
                this.galleryDoc = this.firestore.doc<Gallery>(
                  'agency/' +
                  agencyId +
                  '/experiences/' +
                  res.id +
                  '/gallery/' +
                  gal.id
                )
                addImage.key = gal.id
                addImage.path = ''
                this.galleryDoc.update(addImage)
              })
            }

            if (gallery.imageFour) {
              const randomId = Math.random().toString(36).substring(2)
              //Ref Gallery
              const refGallery = this.storage.ref(
                'agencies/' +
                agencyId +
                '/experiences/' +
                res.id +
                '/gallery/' +
                randomId
              )
              refGallery.put(gallery.imageFour).then((rr) => {
                rr.ref.getDownloadURL().then((refDown) => {
                  const addImage = {
                    key: null,
                    image: refDown,
                    isActive: true,
                    path: null
                  }
                  this.galleriesCollection.add(addImage).then((gal4) => {
                    this.galleryDoc = this.firestore.doc<Gallery>(
                      'agency/' +
                      agencyId +
                      '/experiences/' +
                      res.id +
                      '/gallery/' +
                      gal4.id
                    )
                    addImage.key = gal4.id
                    addImage.path = randomId
                    this.galleryDoc.update(addImage)
                  })
                })
              })
            } else {
              const addImage = {
                key: null,
                image: '',
                isActive: true,
                path: ''
              }
              this.galleriesCollection.add(addImage).then((gal) => {
                this.galleryDoc = this.firestore.doc<Gallery>(
                  'agency/' +
                  agencyId +
                  '/experiences/' +
                  res.id +
                  '/gallery/' +
                  gal.id
                )
                addImage.key = gal.id
                addImage.path = ''
                this.galleryDoc.update(addImage)
              })
            }

            if (gallery.imageFive) {
              const randomId = Math.random().toString(36).substring(2)
              //Ref Gallery
              const refGallery = this.storage.ref(
                'agencies/' +
                agencyId +
                '/experiences/' +
                res.id +
                '/gallery/' +
                randomId
              )
              refGallery.put(gallery.imageFive).then((rr) => {
                rr.ref.getDownloadURL().then((refDown) => {
                  const addImage = {
                    key: null,
                    image: refDown,
                    isActive: true,
                    path: null
                  }
                  this.galleriesCollection.add(addImage).then((gal5) => {
                    this.galleryDoc = this.firestore.doc<Gallery>(
                      'agency/' +
                      agencyId +
                      '/experiences/' +
                      res.id +
                      '/gallery/' +
                      gal5.id
                    )
                    addImage.key = gal5.id
                    addImage.path = randomId
                    this.galleryDoc.update(addImage)
                  })
                })
              })
            } else {
              const addImage = {
                key: null,
                image: '',
                isActive: true,
                path: ''
              }
              this.galleriesCollection.add(addImage).then((gal) => {
                this.galleryDoc = this.firestore.doc<Gallery>(
                  'agency/' +
                  agencyId +
                  '/experiences/' +
                  res.id +
                  '/gallery/' +
                  gal.id
                )
                addImage.key = gal.id
                addImage.path = ''
                this.galleryDoc.update(addImage)
              })
            }

            if (gallery.imageSix) {
              const randomId = Math.random().toString(36).substring(2)
              //Ref Gallery
              const refGallery = this.storage.ref(
                'agencies/' +
                agencyId +
                '/experiences/' +
                res.id +
                '/gallery/' +
                randomId
              )
              refGallery.put(gallery.imageSix).then((rr) => {
                rr.ref.getDownloadURL().then((refDown) => {
                  const addImage = {
                    key: null,
                    image: refDown,
                    isActive: true,
                    path: null
                  }
                  this.galleriesCollection.add(addImage).then((gal6) => {
                    this.galleryDoc = this.firestore.doc<Gallery>(
                      'agency/' +
                      agencyId +
                      '/experiences/' +
                      res.id +
                      '/gallery/' +
                      gal6.id
                    )
                    addImage.key = gal6.id
                    addImage.path = randomId
                    this.galleryDoc.update(addImage)
                  })
                })
              })
            } else {
              const addImage = {
                key: null,
                image: '',
                isActive: true,
                path: ''
              }
              this.galleriesCollection.add(addImage).then((gal) => {
                this.galleryDoc = this.firestore.doc<Gallery>(
                  'agency/' +
                  agencyId +
                  '/experiences/' +
                  res.id +
                  '/gallery/' +
                  gal.id
                )
                addImage.key = gal.id
                addImage.path = ''
                this.galleryDoc.update(addImage)
              })
            }
          }
        })
        .catch((err) => rejected(err))
    })
  }

  public duplicateExperience(
    agencyId: string,
    experience: Experience,
    schedule: ScheduleExperience,
    destinationId?: string
  ) {
    return new Promise((resolve, rejected) => {
      this.firestore.collection<Experience>(
        'agency/' + agencyId + '/experiences'
      )
        .add(experience)
        .then((res) => {
          this.experienceDoc = this.firestore.doc<Experience>(
            'agency/' + agencyId + '/experiences/' + res.id
          )
          experience.key = res.id;

          this.experienceDoc.update({ key: res.id })
          if (experience.key) {
            if (destinationId) {
              this.addKeyToDestinations(agencyId, destinationId, res.id)
            }

            this.scheduleExperiencesCollection =
              this.firestore.collection<ScheduleExperience>(
                'agency/' + agencyId + '/experiences/' + res.id + '/schedule'
              )

            this.galleriesCollection = this.firestore.collection<Gallery>(
              'agency/' + agencyId + '/experiences/' + res.id + '/gallery'
            )

            this.addEmptyImagesForEdit(agencyId, experience.key, true);
            for (let i = 0; i < 5; i++) {
              this.addEmptyImagesForEdit(agencyId, experience.key);
            }

            //Add schedule to experience created
            this.scheduleExperiencesCollection.add(schedule).then((sc) => {
              this.scheduleDoc = this.firestore.doc<ScheduleExperience>(
                'agency/' +
                agencyId +
                '/experiences/' +
                res.id +
                '/schedule/' +
                sc.id
              )
              schedule.key = sc.id
              //Update key shcedule
              this.scheduleDoc.update(schedule).then(() => {
                resolve(true)
              })
            })

            //Add Gallery to experience

            //Ref Image 1 & principalImage
            const randomId = Math.random().toString(36).substring(2)
            const ref = this.storage.ref(
              'agencies/' +
              agencyId +
              '/experiences/' +
              res.id +
              '/gallery/' +
              randomId
            )
          }
        })
        .catch((err) => rejected(err))
    })
  }

  public addExperienceRestaurant(
    agencyId: string,
    experience: Restaurant,
    schedule: ScheduleExperience,
    gallery: { principalImage: any }
  ) {
    return new Promise((resolve, rejected) => {
      this.experiencesRestaurantCollection =
        this.firestore.collection<Restaurant>(
          'agency/' + agencyId + '/experiences'
        )
      //Add experience to collection
      this.experiencesRestaurantCollection
        .add(experience)
        .then((res) => {
          this.experienceRestaurantDoc = this.firestore.doc<Restaurant>(
            'agency/' + agencyId + '/experiences/' + res.id
          )
          experience.key = res.id

          if (experience.key) {
            this.scheduleExperiencesCollection =
              this.firestore.collection<ScheduleExperience>(
                'agency/' + agencyId + '/experiences/' + res.id + '/schedule'
              )

            this.galleriesCollection = this.firestore.collection<Gallery>(
              'agency/' + agencyId + '/experiences/' + res.id + '/gallery'
            )

            //Add Gallery to experience

            //Ref Image 1 & principalImage
            const randomId = Math.random().toString(36).substring(2)
            const ref = this.storage.ref(
              'agencies/' +
              agencyId +
              '/experiences/' +
              res.id +
              '/gallery/' +
              randomId
            )

            //Add Image 1 & principalImage
            ref.put(gallery.principalImage).then((rr) => {
              rr.ref.getDownloadURL().then((refDown) => {
                experience.principalImage = refDown
                //Update key experience
                this.experienceRestaurantDoc.update(experience)
                const galleryAdd = {
                  key: null,
                  image: null,
                  isActive: true,
                  isPrincipalImage: true,
                  path: null
                }
                galleryAdd.image = refDown
                galleryAdd.isActive = true
                galleryAdd.path = randomId
                galleryAdd.isPrincipalImage = true

                this.galleriesCollection.add(galleryAdd).then((gal) => {
                  this.galleryDoc = this.firestore.doc<Gallery>(
                    'agency/' +
                    agencyId +
                    '/experiences/' +
                    res.id +
                    '/gallery/' +
                    gal.id
                  )
                  galleryAdd.key = gal.id
                  this.galleryDoc.update(galleryAdd)
                })
              })
            })

            //Add schedule to experience created
            this.scheduleExperiencesCollection.add(schedule).then((sc) => {
              this.scheduleDoc = this.firestore.doc<ScheduleExperience>(
                'agency/' +
                agencyId +
                '/experiences/' +
                res.id +
                '/schedule/' +
                sc.id
              )
              schedule.key = sc.id
              //Update key shcedule
              this.scheduleDoc.update(schedule).then(() => {
                resolve(res.id)
              })
            })

            this.firestore
              .collection('agency/')
              .doc(agencyId)
              .get()
              .toPromise()
              .then((agencyRes) => {
                this.logsService.dataHistory(
                  agencyId,
                  experience,
                  experience.key,
                  null,
                  'POST',
                  agencyRes.data()
                )
              })
          }
        })
        .catch((err) => rejected(err))
    })
  }

  public addExperienceHotel(
    agencyId: string,
    experience: Hotel,
    schedule: ScheduleExperience,
    gallery: { principalImage: any; imageTwo: any; imageThree: any; imageFour: any; imageFive: any; imageSix: any },
    destinationId?: string,
    isTailorMade?: boolean
  ) {
    return new Promise((resolve, rejected) => {
      this.experiencesHotelCollection = this.firestore.collection<Hotel>(
        'agency/' + agencyId + '/experiences'
      )
      //Add experience to collection
      this.experiencesHotelCollection
        .add(experience)
        .then((res) => {
          this.experienceHotelDoc = this.firestore.doc<Hotel>(
            'agency/' + agencyId + '/experiences/' + res.id
          )
          experience.key = res.id

          if (experience.key) {
            if (destinationId) {
              this.addKeyToDestinations(agencyId, destinationId, experience.key)
            }

            if (!isTailorMade) {
              this.createExperienceWebsite(agencyId, experience.key);
            }

            this.scheduleExperiencesCollection =
              this.firestore.collection<ScheduleExperience>(
                'agency/' + agencyId + '/experiences/' + res.id + '/schedule'
              )

            this.galleriesCollection = this.firestore.collection<Gallery>(
              'agency/' + agencyId + '/experiences/' + res.id + '/gallery'
            )

            //Add Gallery to experience

            //Ref Image 1 & principalImage
            const randomId = Math.random().toString(36).substring(2)
            const ref = this.storage.ref(
              'agencies/' +
              agencyId +
              '/experiences/' +
              res.id +
              '/gallery/' +
              randomId
            )

            //Add Image 1 & principalImage
            ref.put(gallery.principalImage).then((rr) => {
              rr.ref.getDownloadURL().then((refDown) => {
                experience.principalImage = refDown
                //Update key experience
                this.experienceHotelDoc.update(experience)
                const galleryAdd = {
                  key: null,
                  image: null,
                  isActive: true,
                  isPrincipalImage: true,
                  path: null
                }
                galleryAdd.image = refDown
                galleryAdd.isActive = true
                galleryAdd.path = randomId
                galleryAdd.isPrincipalImage = true

                this.galleriesCollection.add(galleryAdd).then((gal) => {
                  this.galleryDoc = this.firestore.doc<Gallery>(
                    'agency/' +
                    agencyId +
                    '/experiences/' +
                    res.id +
                    '/gallery/' +
                    gal.id
                  )
                  galleryAdd.key = gal.id
                  this.galleryDoc.update(galleryAdd)
                })
              })
            })

            this.firestore
              .collection('agency/')
              .doc(agencyId)
              .get()
              .toPromise()
              .then((agencyRes) => {
                this.logsService.dataHistory(
                  agencyId,
                  experience,
                  experience.key,
                  null,
                  'POST',
                  agencyRes.data()
                )
              })

            //Add Image 2 & 3 if exist
            if (gallery.imageTwo) {
              const randomId = Math.random().toString(36).substring(2)
              //Ref Gallery
              const refGallery = this.storage.ref(
                'agencies/' +
                agencyId +
                '/experiences/' +
                res.id +
                '/gallery/' +
                randomId
              )
              refGallery.put(gallery.imageTwo).then((rr) => {
                rr.ref.getDownloadURL().then((refDown) => {
                  const addImage = {
                    key: null,
                    image: refDown,
                    isActive: true,
                    path: null
                  }
                  this.galleriesCollection.add(addImage).then((gal2) => {
                    this.galleryDoc = this.firestore.doc<Gallery>(
                      'agency/' +
                      agencyId +
                      '/experiences/' +
                      res.id +
                      '/gallery/' +
                      gal2.id
                    )
                    addImage.key = gal2.id
                    addImage.path = randomId
                    this.galleryDoc.update(addImage)
                  })
                })
              })
            } else {
              const addImage = {
                key: null,
                image: '',
                isActive: true,
                path: ''
              }
              this.galleriesCollection.add(addImage).then((gal) => {
                this.galleryDoc = this.firestore.doc<Gallery>(
                  'agency/' +
                  agencyId +
                  '/experiences/' +
                  res.id +
                  '/gallery/' +
                  gal.id
                )
                addImage.key = gal.id
                addImage.path = ''
                this.galleryDoc.update(addImage)
              })
            }

            if (gallery.imageThree) {
              const randomId = Math.random().toString(36).substring(2)
              //Ref Gallery
              const refGallery = this.storage.ref(
                'agencies/' +
                agencyId +
                '/experiences/' +
                res.id +
                '/gallery/' +
                randomId
              )
              refGallery.put(gallery.imageThree).then((rr) => {
                rr.ref.getDownloadURL().then((refDown) => {
                  const addImage = {
                    key: null,
                    image: refDown,
                    isActive: true,
                    path: null
                  }
                  this.galleriesCollection.add(addImage).then((gal3) => {
                    this.galleryDoc = this.firestore.doc<Gallery>(
                      'agency/' +
                      agencyId +
                      '/experiences/' +
                      res.id +
                      '/gallery/' +
                      gal3.id
                    )
                    addImage.key = gal3.id
                    addImage.path = randomId
                    this.galleryDoc.update(addImage)
                  })
                })
              })
            } else {
              const addImage = {
                key: null,
                image: '',
                isActive: true,
                path: ''
              }
              this.galleriesCollection.add(addImage).then((gal) => {
                this.galleryDoc = this.firestore.doc<Gallery>(
                  'agency/' +
                  agencyId +
                  '/experiences/' +
                  res.id +
                  '/gallery/' +
                  gal.id
                )
                addImage.key = gal.id
                addImage.path = ''
                this.galleryDoc.update(addImage)
              })
            }

            if (gallery.imageFour) {
              const randomId = Math.random().toString(36).substring(2)
              //Ref Gallery
              const refGallery = this.storage.ref(
                'agencies/' +
                agencyId +
                '/experiences/' +
                res.id +
                '/gallery/' +
                randomId
              )
              refGallery.put(gallery.imageFour).then((rr) => {
                rr.ref.getDownloadURL().then((refDown) => {
                  const addImage = {
                    key: null,
                    image: refDown,
                    isActive: true,
                    path: null
                  }
                  this.galleriesCollection.add(addImage).then((gal4) => {
                    this.galleryDoc = this.firestore.doc<Gallery>(
                      'agency/' +
                      agencyId +
                      '/experiences/' +
                      res.id +
                      '/gallery/' +
                      gal4.id
                    )
                    addImage.key = gal4.id
                    addImage.path = randomId
                    this.galleryDoc.update(addImage)
                  })
                })
              })
            } else {
              const addImage = {
                key: null,
                image: '',
                isActive: true,
                path: ''
              }
              this.galleriesCollection.add(addImage).then((gal) => {
                this.galleryDoc = this.firestore.doc<Gallery>(
                  'agency/' +
                  agencyId +
                  '/experiences/' +
                  res.id +
                  '/gallery/' +
                  gal.id
                )
                addImage.key = gal.id
                addImage.path = ''
                this.galleryDoc.update(addImage)
              })
            }

            if (gallery.imageFive) {
              const randomId = Math.random().toString(36).substring(2)
              //Ref Gallery
              const refGallery = this.storage.ref(
                'agencies/' +
                agencyId +
                '/experiences/' +
                res.id +
                '/gallery/' +
                randomId
              )
              refGallery.put(gallery.imageFive).then((rr) => {
                rr.ref.getDownloadURL().then((refDown) => {
                  const addImage = {
                    key: null,
                    image: refDown,
                    isActive: true,
                    path: null
                  }
                  this.galleriesCollection.add(addImage).then((gal5) => {
                    this.galleryDoc = this.firestore.doc<Gallery>(
                      'agency/' +
                      agencyId +
                      '/experiences/' +
                      res.id +
                      '/gallery/' +
                      gal5.id
                    )
                    addImage.key = gal5.id
                    addImage.path = randomId
                    this.galleryDoc.update(addImage)
                  })
                })
              })
            } else {
              const addImage = {
                key: null,
                image: '',
                isActive: true,
                path: ''
              }
              this.galleriesCollection.add(addImage).then((gal) => {
                this.galleryDoc = this.firestore.doc<Gallery>(
                  'agency/' +
                  agencyId +
                  '/experiences/' +
                  res.id +
                  '/gallery/' +
                  gal.id
                )
                addImage.key = gal.id
                addImage.path = ''
                this.galleryDoc.update(addImage)
              })
            }

            if (gallery.imageSix) {
              const randomId = Math.random().toString(36).substring(2)
              //Ref Gallery
              const refGallery = this.storage.ref(
                'agencies/' +
                agencyId +
                '/experiences/' +
                res.id +
                '/gallery/' +
                randomId
              )
              refGallery.put(gallery.imageSix).then((rr) => {
                rr.ref.getDownloadURL().then((refDown) => {
                  const addImage = {
                    key: null,
                    image: refDown,
                    isActive: true,
                    path: null
                  }
                  this.galleriesCollection.add(addImage).then((gal6) => {
                    this.galleryDoc = this.firestore.doc<Gallery>(
                      'agency/' +
                      agencyId +
                      '/experiences/' +
                      res.id +
                      '/gallery/' +
                      gal6.id
                    )
                    addImage.key = gal6.id
                    addImage.path = randomId
                    this.galleryDoc.update(addImage)
                  })
                })
              })
            } else {
              const addImage = {
                key: null,
                image: '',
                isActive: true,
                path: ''
              }
              this.galleriesCollection.add(addImage).then((gal) => {
                this.galleryDoc = this.firestore.doc<Gallery>(
                  'agency/' +
                  agencyId +
                  '/experiences/' +
                  res.id +
                  '/gallery/' +
                  gal.id
                )
                addImage.key = gal.id
                addImage.path = ''
                this.galleryDoc.update(addImage)
              })
            }

            //Add schedule to experience created
            this.scheduleExperiencesCollection.add(schedule).then((sc) => {
              this.scheduleDoc = this.firestore.doc<ScheduleExperience>(
                'agency/' +
                agencyId +
                '/experiences/' +
                res.id +
                '/schedule/' +
                sc.id
              )
              schedule.key = sc.id
              //Update key shcedule
              this.scheduleDoc.update(schedule).then(() => {
                resolve(true)
              })
            })
          }
        })
        .catch((err) => rejected(err))
    })
  }

  public addExperienceEcommerce(
    agencyId: string,
    experience: Ecommerce,
    gallery: { principalImage: any }
  ) {
    return new Promise((resolve, rejected) => {
      this.experiencesEcommerceCollection =
        this.firestore.collection<Ecommerce>(
          'agency/' + agencyId + '/experiences'
        )
      //Add experience to collection
      this.experiencesEcommerceCollection
        .add(experience)
        .then((res) => {
          this.experienceEcommerceDoc = this.firestore.doc<Ecommerce>(
            'agency/' + agencyId + '/experiences/' + res.id
          )
          experience.key = res.id

          if (experience.key) {
            this.galleriesCollection = this.firestore.collection<Gallery>(
              'agency/' + agencyId + '/experiences/' + res.id + '/gallery'
            )

            //Add Gallery to experience

            //Ref Image 1 & principalImage
            const randomId = Math.random().toString(36).substring(2)
            const ref = this.storage.ref(
              'agencies/' +
              agencyId +
              '/experiences/' +
              res.id +
              '/gallery/' +
              randomId
            )

            //Add Image 1 & principalImage
            ref.put(gallery.principalImage).then((rr) => {
              rr.ref.getDownloadURL().then((refDown) => {
                experience.principalImage = refDown
                //Update key experience
                this.experienceEcommerceDoc.update(experience)
                const galleryAdd = {
                  key: null,
                  image: null,
                  isActive: true,
                  isPrincipalImage: true,
                  path: null
                }
                galleryAdd.image = refDown
                galleryAdd.isActive = true
                galleryAdd.path = randomId
                galleryAdd.isPrincipalImage = true

                this.galleriesCollection.add(galleryAdd).then((gal) => {
                  this.galleryDoc = this.firestore.doc<Gallery>(
                    'agency/' +
                    agencyId +
                    '/experiences/' +
                    res.id +
                    '/gallery/' +
                    gal.id
                  )
                  galleryAdd.key = gal.id
                  this.galleryDoc.update(galleryAdd)
                  resolve(res.id)
                })
              })
            })

            // this.firestore
            //   .collection('agency/')
            //   .doc(agencyId)
            //   .get()
            //   .toPromise()
            //   .then((agencyRes) => {
            //     this.logsService.dataHistory(
            //       agencyId,
            //       experience,
            //       experience.key,
            //       null,
            //       'POST',
            //       agencyRes.data()
            //     )
            //   })
          }
        })
        .catch((err) => {
          console.log('ERROR', err)
          rejected(err)
        })
    })
  }

  public updateSchedule(
    agencyId: string,
    experienceId: string,
    schedule: Partial<ScheduleExperience>
  ) {
    return new Promise((resolve, reject) => {
      this.scheduleDoc = this.firestore.doc<ScheduleExperience>(
        'agency/' +
        agencyId +
        '/experiences/' +
        experienceId +
        '/schedule/' +
        schedule.key
      )

      this.scheduleDoc
        .update(schedule)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  public getExperienceById(agencyId, experienceId) {
    return this.firestore
      .collection<Experience>('agency/' + agencyId + '/experiences/')
      .doc(experienceId)
      .valueChanges()
  }

  public async getExperienceByIdPromise(agencyId, experienceId) {
    const experienceDoc = await this.firestore
      .collection<Experience>('agency/' + agencyId + '/experiences/')
      .doc(experienceId)
      .get()
      .toPromise();

    return experienceDoc.data() as Experience;
  }

  public async getExperienceByIdAsync(agencyId, experienceId) {
    return new Promise((resolve, reject) => {
      const ref = this.firestore.collection(`agency/${agencyId}/experiences`).doc(experienceId).get();
      ref.forEach(res => {
        resolve(res.data())
      })
    })
  }

  public getScheduleExperience(agencyId, experienceId) {
    return this.firestore
      .collection<ScheduleExperience>(
        'agency/' + agencyId + '/experiences/' + experienceId + '/schedule'
      )
      .valueChanges()
  }

  public getServicesBussy(data) {
    return this.firestore
      .collection<Service>(
        `agency/${data.agencyId}/experiences/${data.experienceId}/services`,
        (ref) => ref.where('day', '==', data.day).where('hour', '==', data.hour)
      )
      .valueChanges()
  }

  public getAllReservations(data) {
    return this.firestore
      .collection<Reservation>(
        `agency/${data.agencyId}/experiences/${data.experienceId}/reservation`,
        (ref) =>
          ref
            .where(
              'paymentStatus',
              '==',
              StatePaymentReservation.PAYMENT_SUCCESS
            )
            .where('status', '==', StateReservation.PAID_OUT)
      )
      .valueChanges()
  }

  // EDIT EXPERIENCE
  public updatePrincipalDataRestaurant(
    agencyId: string,
    experienceId: string,
    dataEdited: Restaurant
  ) {
    return new Promise((resolve, reject) => {
      this.experienceRestaurantDoc = this.firestore.doc<Restaurant>(
        'agency/' + agencyId + '/experiences/' + experienceId
      )

      this.firestore
        .collection('agency/')
        .doc(agencyId)
        .get()
        .toPromise()
        .then((agencyRes) => {
          this.experienceRestaurantDoc
            .get()
            .toPromise()
            .then((expRes) => {
              this.experienceRestaurantDoc
                .update(dataEdited)
                .then(() => {
                  resolve(true)
                  this.logsService.dataHistory(
                    agencyId,
                    expRes.data(),
                    experienceId,
                    null,
                    'PUT',
                    agencyRes.data()
                  )
                })
                .catch((err) => {
                  reject(err)
                })
            })
        })
    })
  }

  public updatePrincipalData(
    agencyId: string,
    experienceId: string,
    dataEdited: Experience
  ) {
    return new Promise((resolve, reject) => {
      this.experienceDoc = this.firestore.doc<Experience>(
        'agency/' + agencyId + '/experiences/' + experienceId
      )

      this.firestore
        .collection('agency/')
        .doc(agencyId)
        .get()
        .toPromise()
        .then((agencyRes) => {
          this.experienceDoc
            .get()
            .toPromise()
            .then((expRes) => {
              this.experienceDoc
                .update(dataEdited)
                .then((res) => {

                  //this.updateWebsiteData(agencyId, experienceId);

                  resolve(res)
                  this.logsService.dataHistory(
                    agencyId,
                    expRes.data(),
                    experienceId,
                    null,
                    'PUT',
                    agencyRes.data()
                  )
                })
                .catch((err) => {
                  reject(err)
                })
            })
        })
    })
  }

  public updateSeoSetting(
    agencyId: string,
    experienceId: string,
    dataEdited: Experience
  ) {
    return new Promise((resolve, reject) => {
      this.experienceDoc = this.firestore.doc<Experience>(
        'agency/' + agencyId + '/experiences/' + experienceId
      )

      this.firestore
        .collection('agency/')
        .doc(agencyId)
        .get()
        .toPromise()
        .then((agencyRes) => {
          this.experienceDoc
            .get()
            .toPromise()
            .then((expRes) => {
              this.experienceDoc
                .update(dataEdited)
                .then((res) => {
                  resolve(res)
                  this.logsService.dataHistory(
                    agencyId,
                    expRes.data(),
                    experienceId,
                    null,
                    'PUT',
                    agencyRes.data()
                  )
                })
                .catch((err) => {
                  reject(err)
                })
            })
        })
    })
  }

  public updatePrincipalDataHotel(
    agencyId: string,
    experienceId: string,
    dataEdited: Hotel
  ) {
    return new Promise((resolve, reject) => {
      this.experienceHotelDoc = this.firestore.doc<Hotel>(
        'agency/' + agencyId + '/experiences/' + experienceId
      )

      this.firestore
        .collection('agency/')
        .doc(agencyId)
        .get()
        .toPromise()
        .then((agencyRes) => {
          this.experienceHotelDoc
            .get()
            .toPromise()
            .then((expRes) => {
              this.experienceHotelDoc
                .update(dataEdited)
                .then((res) => {
                  resolve(res)
                  this.logsService.dataHistory(
                    agencyId,
                    expRes.data(),
                    experienceId,
                    null,
                    'PUT',
                    agencyRes.data()
                  )
                })
                .catch((err) => {
                  reject(err)
                })
            })
        })
    })
  }

  public updateDisableExperience(agencyId, experienceId, isDisable) {
    return new Promise((resolve, reject) => {
      this.firestore
        .doc(`agency/${agencyId}/experiences/${experienceId}`)
        .update({ isDisable })
        .then((res) => resolve(true))
        .catch((err) => reject(false))
    })
  }

  async checkCurrentSlug(name, agencyId) {
    return new Promise(async (resolve) => {
      const slug = Slug.stringToSlug(name);
      const experiencesRef = this.firestore.collection(`agency/${agencyId}/experiences`).get();

      await experiencesRef.forEach(exp => {
        exp.forEach(e => {
          const { seo } = e.data();
          if (!!seo) {
            // console.log(slug === seo.slug);
            if (slug === seo.slug) {
              const lastChart = slug.charAt(slug.length - 1);
              const nanVerify = parseInt(slug.charAt(slug.length - 1));
              if (!Number.isNaN(nanVerify)) {
                resolve(slug.slice(0, -1) + (nanVerify + 1))
              } else {
                resolve(slug.slice(0, -1) + lastChart + "-1")
              }
            }
          }
        })
      })

      resolve('NOT_CURRENT_SLUG')
    })

    //Validate Slug
    // const lastChart = parseInt(name.charAt(name.length-1));
    // if(!Number.isNaN(lastChart)){
    // var newSlug = name.slice(0, -1) + (lastChart+1);
    // // console.log(newSlug)
    // }
  }

  public getExperiencesNameByAgency(agencyId: string) {
    return this.firestore
      .collection<Experience>('agency/' + agencyId + '/experiences', (ref) =>
        ref.where('isActive', '==', true)
      )
      .valueChanges()
      .pipe(
        map((experiences: Experience[]) => experiences.map(exp => exp.name))
      );
  }

  public createExperienceWebsite(agencyId: string, experienceId: string) {
    this.firestore.doc<Agency>('agency/' + agencyId)
      .valueChanges()
      .pipe(
        map((agency: Agency) => agency?.web || '') // Si agency.web no existe, devuelve un string vacío
      ).subscribe((web) => {
        this.http.post(
          web + '/wp-json/heyandes/v1/experiences?agency_key=' + agencyId + '&experience_key=' + experienceId, {}
        ).subscribe(
          () => {
            // console.log('Experiencia creada en la web');
          },
          (error) => {
            console.error('Error en la solicitud HTTP:', error);
          }
        );
      });
  }

  public updateExperienceWebsite(agencyId: string, experienceId: string) {
    this.firestore.doc<Agency>('agency/' + agencyId)
      .valueChanges()
      .pipe(
        map((agency: Agency) => agency?.web || '') // Si agency.web no existe, devuelve un string vacío
      ).subscribe((web) => {
        this.http.put(
          web + '/wp-json/heyandes/v1/experiences?agency_key=' + agencyId + '&experience_key=' + experienceId, {}
        ).subscribe(
          () => {
            // console.log('Experiencia actualizada en la web');
          },
          (error) => {
            console.error('Error en la solicitud HTTP:', error);
          }
        );
      });
  }

  //Nota: No borra la página, sólo se elimina la card para no perder poscionamiento
  public deleteExperienceWebsite(agencyId: string, experienceId: string) {
    this.firestore.doc<Agency>('agency/' + agencyId)
      .valueChanges()
      .pipe(
        map((agency: Agency) => agency?.web || '') // Si agency.web no existe, devuelve un string vacío
      ).subscribe((web) => {
        this.http.delete(
          web + '/wp-json/heyandes/v1/experiences?agency_key=' + agencyId + '&experience_key=' + experienceId, {}
        ).subscribe(
          () => {
            // console.log('Card de experiencia eliminada en la web');
          },
          (error) => {
            console.error('Error en la solicitud HTTP:', error);
          }
        );
      });
  }

  public getExchange(currency = 'USD', amount = 10000) {
    const url = `${environment.urlApiCurrency}`;
    const params = {
      have: 'CLP',
      want: `${currency}`,
      amount: `${amount}` //Entre menor es el amount es menos preciso para obtener el ratio, se dejó en 10000 por mejor precision
    };
    const headers = {
      'X-Api-Key': `${environment.apiKeyCurrency}`,
      'Content-Type': 'application/json'
    };

    const options = {
      params,
      headers
    };

    const observable = this.http.get(url, options);

    return observable.pipe(
      map(
        (response: any) => {
          return response.old_amount / response.new_amount;
        }
      ),
      first()
    ).toPromise();
  }

  public async getExperienceName(agencyId: string, experienceId: string) {
    const experience = await this.firestore.doc<Experience>('agency/' + agencyId + '/experiences/' + experienceId)
      .get().toPromise();

    if (!experience.exists) return '';

    const exp = experience.data() as Experience;

    //Retorna '' si la experiencia está desactivada
    return exp.isActive ? exp.name : '';
  }

  public async deleteDestinationExperience(agencyId: string, experienceId: string, destinationId: string) {
    return new Promise((resolve, reject) => {
      const destinationDoc = this.firestore.doc<Destination>(
        'agency/' + agencyId + '/destinations/' + destinationId
      );

      destinationDoc.valueChanges().subscribe(async (destination: Destination) => {
        const updateExperiences = destination.experiences.filter(experience => experience !== experienceId);
        await destinationDoc.update({ experiences: updateExperiences }).then(() => {
          resolve(true);
        }).catch((error) => {
          reject(error);
        });
      })

    })
  }

  //Obtiene el listado de nombre de experiencias a partir de la categoria.
  public getExperiencesNamesByAgencyAndCategory(agencyId: string, category: string, name: string) {
    return this.firestore
      .collection<Experience>('agency/' + agencyId + '/experiences', (ref) =>
        ref.where('isActive', '==', true).where('category', '==', category)
      )
      .valueChanges()
      .pipe(
        map((experiences: Experience[]) =>
          experiences
            .filter(exp => exp.name !== name)
            .map(exp => ({
              name: exp.name,
              category: exp.category,
              type: exp.type,
              periodicity: exp.periodicity,
              key: exp.key,
              minConfirmation: exp?.minConfirmation ? exp.minConfirmation : 0
            }))
        )
      );
  }

  public async getScheduleExperienceObject(agencyId, experienceId) {
    const scheduleRef = this.firestore.collection('agency').doc(agencyId).collection('experiences').doc(experienceId).collection('schedule');
    let data = {};

    const querySnapshot = await scheduleRef.ref
      .get();

    querySnapshot.forEach((doc) => {
      data = { ...doc.data(), key: doc.id };
    });

    return data;
  }

  public async getAllServices(agencyId: string, experienceId: string) {
    const serviceRef = this.firestore.collection('agency').doc(agencyId).collection('experiences').doc(experienceId).collection('services');
    let services = []

    const querySnapshot = await serviceRef.ref
      .get();

    const promises = querySnapshot.docs.map(async (serviceDoc) => {
      return {
        ...serviceDoc.data(), key: serviceDoc.id
      };
    });

    const results = await Promise.all(promises);

    services = results.filter((result) => result !== null);

    return services;
  }

  public async getResourcesByDayAndHour(agencyId: string, experienceId: string){
    const resourceRef = this.firestore.collection('agency').doc(agencyId).collection('resources');
    const resources = [];

    const querySnapshot = await resourceRef.ref
      .where('idsExperiences', 'array-contains', experienceId)
      .get();

    querySnapshot.forEach((doc) => {
      const resourceData = doc.data();
      if (resourceData.isActive){
        resources.push({
          id: doc.id,
          ...resourceData,
        })
      }
    });

    return resources
  }


  public async getExperienceSchedule(agencyId: string, experienceId: string){
    try {
      const scheduleRef = this.firestore.collection('agency').doc(agencyId).collection('experiences').doc(experienceId).collection('schedule').ref;

      const scheduleResponse = await scheduleRef
        .get();

      let data: {days?:any} = {};
      scheduleResponse.forEach((doc) => (data = { ...doc.data() }));
      return data;
    } catch (err) {
      return { days: {} , error: err };
    }
  };

  public async getExperienceIdByChannelRoomId(agencyId, roomId){
    const experiences = await this.firestore
      .collection('agency')
      .doc(agencyId)
      .collection('experiences', ref => ref.where('channelRoomId', '==', roomId))
      .get().toPromise();

    return experiences.docs[0].id
  }
}
