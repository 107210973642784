import { Injectable } from '@angular/core'
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection
} from '@angular/fire/firestore'
import { AngularFireAuth } from '@angular/fire/auth'
import { environment } from 'src/environments/environment'
import { CookiesService } from './cookies.service'
import { Router } from '@angular/router'
import { map, take, tap } from 'rxjs/operators'
import { AdminAgency } from '../models/admin-agency'
import { RoleValidator } from '../helpers/roleValidator'
import { Observable, of, throwError } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import axios from 'axios'
@Injectable({
  providedIn: 'root'
})
export class AuthService extends RoleValidator {
  public user$: Observable<AdminAgency>
  public isTypeLogin: any = null
  public isSuperAdmin: any = null
  // public isAgent: any = null;
  public isAgentSecundary: any = null
  public uid: string = null
  public sidebarnavItems: any[]
  public typeUser: any = null
  constructor(
    private afAuth: AngularFireAuth,
    private coockServ: CookiesService,
    private router: Router,
    private fs: AngularFirestore,
    private cookiesApi: CookiesService
  ) {
    super()
    this.user$ = this.afAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          return this.fs.doc<AdminAgency>(`admins/${user.uid}`).valueChanges()
        }
        return of(null)
      })
    )
  }

  async singIn(email, password) {
    try {
      const { user } = await this.afAuth.auth.signInWithEmailAndPassword(
        email,
        password
      )
      // this.updateUserData(user);
      const authData = JSON.parse(JSON.stringify(user))
      const accessToken = authData.stsTokenManager.accessToken
      this.coockServ.setCookie(
        `${environment.helpers.cookies.name}.claims`,
        JSON.stringify(user),
        1
      )
      this.coockServ.setCookie(
        `${environment.helpers.cookies.name}.token`,
        accessToken,
        1
      )
      return user
    } catch (error) {
      return error
    }
  }

  async logout(): Promise<void> {
    try {
      await this.afAuth.auth.signOut()
    } catch (error) {
      console.log(error)
    }
  }

  isAuth() {
    return this.afAuth.authState.pipe(map((auth) => auth))
  }

  async getSignInToken(user) {
    const tokenReponse = await axios({
      method: 'GET',
      url: `${environment.urlAuth}/auth/${user}`,
      headers: { 'Content-Type': 'application/json' }
    })

    return tokenReponse
  }
  // public async singIn(email, password) {
  //   return new Promise(async (resolve, reject) => {
  //     await this.af.auth
  //       .signInWithEmailAndPassword(email, password)
  //       .then((res) => {
  //         const authData = JSON.parse(JSON.stringify(res.user));
  //         const accessToken = authData.stsTokenManager.accessToken;

  //         this.coockServ.setCookie(
  //           `${environment.helpers.cookies.name}.claims`,
  //           JSON.stringify(res.user),
  //           1
  //         );
  //         this.coockServ.setCookie(`${environment.helpers.cookies.name}.token`, accessToken, 1);
  //         resolve(res);
  //       })
  //       .catch((err) => reject(err));
  //   });
  // }

  // isLoggedIn() {
  //   const sess = this.coockServ.getCookie(`${environment.helpers.cookies.name}.token`);
  //   const claims = this.coockServ.getCookie(`${environment.helpers.cookies.name}.claims`);
  //   if (sess && claims) {
  //     return true;
  //   } else {
  //     this.router.navigate(["/auth"]);
  //     return false;
  //   }
  // }

  // isAuth() {
  //   return this.af.authState.pipe(map((auth) => auth));
  // }

  // isUserAdmin(uid) {
  //   return this.fs.doc<AdminAgency>(`admins/${uid}`).valueChanges();
  // }

  // getCurrentUser() {
  //   return new Promise((resolve) => {
  //     this.isAuth().subscribe(async (auth) => {
  //       if (auth) {
  //         this.uid = auth.uid;
  //         this.isUserAdmin(this.uid).subscribe((user) => {
  //           resolve(user);
  //         });
  //       }
  //     });
  //   });
  // }

  // logOut() {
  //   return this.af.auth.signOut().then(() => {
  //     this.coockServ.deleteCookie(`${environment.helpers.cookies.name}.token`);
  //     this.coockServ.deleteCookie(`${environment.helpers.cookies.name}.claims`);
  //     this.router.navigate(["/auth/login"]);
  //   });
  // }

  // isLoggedAdmin() {
  //   // this is for the open close
  //   this.isAuth().subscribe((auth) => {
  //     if (auth) {
  //       this.uid = auth.uid;
  //       this.isUserAdmin(this.uid).subscribe((userRole) => {
  //         this.isSuperAdmin = Object.assign({}, userRole.roles).hasOwnProperty("superadmin");
  //       });
  //     }
  //   });

  //   return this.isSuperAdmin;
  // }

  // async getRolUser() {
  //   return new Promise((resolve) => {
  //     this.isAuth().subscribe(async (auth) => {
  //       if (auth) {
  //         this.uid = auth.uid;
  //         await this.isUserAdmin(this.uid).subscribe((userRole) => {
  //           const rol = Object.assign({}, userRole.roles);
  //           let type = "";
  //           if (rol.hasOwnProperty("superadmin")) {
  //             type = "SUPERADMIN";
  //           } else if (rol.hasOwnProperty("agent")) {
  //             type = "AGENT";
  //           } else if (rol.hasOwnProperty("agentSecondary")) {
  //             type = "AGENTSECONDARY";
  //           } else if (rol.hasOwnProperty("agency")) {
  //             type = "AGENCY";
  //           }
  //           resolve(type);
  //         });
  //       }
  //     });
  //   });
  //}

  //

  async resetPassword(emailUser) {
    try {
      await this.afAuth.auth.sendPasswordResetEmail(emailUser)
      return { sendEmail: true }
    } catch (err) {
      return { sendEmail: false, error: err }
    }
  }
}
