import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelService {
  constructor() { }

  public exportAsExcelFile(json: any[], jsonDeals: any[], jsonAddOns: any[], jsonRefunds: any[], jsonChannel: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const worksheetDeals: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonDeals);
    const worksheetRefunds: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonRefunds);
    const worksheetChannel: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonChannel);
    const worksheetAddOns: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonAddOns);

    const workbook: XLSX.WorkBook = { Sheets: { 'Ventas': worksheet, 'Deals': worksheetDeals, 'Reembolsos': worksheetRefunds, 'Reservas Channel Manager': worksheetChannel, 'Add-Ons': worksheetAddOns }, SheetNames: ['Ventas', 'Deals', 'Reembolsos', 'Reservas Channel Manager', 'Add-Ons'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportStatistics(services: any[], giftCard: any[], discount: any[], deals: any[], excelFileName: string) {
    const worksheetServices: XLSX.WorkSheet = XLSX.utils.json_to_sheet(services);
    const worksheetGiftCard: XLSX.WorkSheet = XLSX.utils.json_to_sheet(giftCard);
    const worksheetDiscount: XLSX.WorkSheet = XLSX.utils.json_to_sheet(discount);
    const worksheetDeals: XLSX.WorkSheet = XLSX.utils.json_to_sheet(deals);

    const workbook: XLSX.WorkBook = {
      Sheets: {
        'Servicios': worksheetServices,
        'Gift Card': worksheetGiftCard,
        'Código de descuento': worksheetDiscount,
        'Deals': worksheetDeals
      }, SheetNames: ['Servicios', 'Gift Card', 'Código de descuento', 'Deals']
    };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportFunnel(jsonDeals: any[], jsonContacted: any[], jsonFinished: any[], jsonLose: any[], excelFileName: string): void {
    const worksheetDeals: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonDeals);
    const worksheetContacted: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonContacted);
    const worksheetFinished: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonFinished);
    const worksheetLose: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonLose);

    const workbook: XLSX.WorkBook = { Sheets: { 'Deals': worksheetDeals, 'Contactado': worksheetContacted, 'Ganado': worksheetFinished, 'Perdido': worksheetLose }, SheetNames: ['Deals', 'Contactado', 'Ganado', 'Perdido'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportDeals(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'Base_de_datos': worksheet }, SheetNames: ['Base_de_datos'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportAsExcelFileWP(json: any[], excelFileName: string): void {

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'Ventas': worksheet }, SheetNames: ['Ventas'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportAsExcelFilePassenger(json: any[], jsonDeals: any[], excelFileName: string): void {

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const worksheetDeals: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonDeals);
    const workbook: XLSX.WorkBook = { Sheets: { 'Pasajeros': worksheet, 'Reservas': worksheetDeals }, SheetNames: ['Pasajeros', 'Reservas'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportAsExcelAllData(vendors: any[], addons: any[], experiences: any[], excelFileName: string): void {

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(vendors);
    const worksheetDeals: XLSX.WorkSheet = XLSX.utils.json_to_sheet(addons);
    const worksheetExperiences: XLSX.WorkSheet = XLSX.utils.json_to_sheet(experiences);
    const workbook: XLSX.WorkBook = {
      Sheets: {
        'Vendedores': worksheet,
        'Addons': worksheetDeals,
        'Experiencias': worksheetExperiences
      },
      SheetNames: ['Vendedores', 'Addons', 'Experiencias']
    };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public importFromFile(bstr: string): XLSX.AOA2SheetOpts {
    /* read workbook */
    const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

    /* grab first sheet */
    const wsname: string = wb.SheetNames[0];
    const ws: XLSX.WorkSheet = wb.Sheets[wsname];

    /* save data */
    const data = <XLSX.AOA2SheetOpts>(XLSX.utils.sheet_to_json(ws, { header: 1 }));

    return data;
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

}