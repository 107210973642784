import { Injectable } from '@angular/core'
import { take } from 'rxjs/operators'
import { AdminAgency } from '../models/admin-agency'
import { Agency } from '../models/agency'
import { Hotel } from '../models/hotel'
import { Options } from '../models/options-date'
import { Restaurant } from '../models/restaurant'
// Services
import { AngularFirestore } from '@angular/fire/firestore'
import { AuthService } from './auth.service'
import { Experience } from '../models/experience'

@Injectable({
  providedIn: 'root'
})
export class LogsService {
  isLoading: boolean

  adminAgencies
  agent
  action: string
  getExperience: Experience | Hotel | Restaurant
  data = {}

  constructor(
    private authApi: AuthService,
    private firestore: AngularFirestore
  ) {
    this.authApi.user$.pipe(take(1)).subscribe((res) => {
      if (res.type === 'Agent' || res.type === 'AgentSecondary') {
        this.agent = res
        this.isLoading = false
      } else if (res.type === 'SuperAdmin') {
        this.agent = res
        this.isLoading = false
      }
    })
  }

  dataHistory(
    agencyId: string,
    experience: Experience | Hotel | Restaurant | null,
    experienceId: string | null,
    adminAgency: AdminAgency | null,
    method: string,
    agency: any | null
  ) {
    const options: Options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }
    const language = navigator.language.split('-')[0]
    const dateNow = new Date().toLocaleTimeString(language, options)

    switch (method) {
      case 'POST':
        this.action = 'CREO'
        break
      case 'PUT':
        this.action = 'EDITO'
        break
      case 'DELETE':
        this.action = 'ELIMINO'
        break
    }

    if (experienceId) {
      this.data = {
        method,
        agencyId,
        experienceId,
        log: `${this.agent.name} ${this.action} la experiencia llamada "${experience.name}" de categoria "${experience.category}" el ${dateNow} de la empresa ${agency.name}`,
        createdAt: dateNow
      }
    } else if (adminAgency) {
      this.data = {
        method,
        agencyId,
        adminAgencyId: adminAgency.key,
        log: `${this.agent.name} ${this.action} un administrador de tipo "${adminAgency.type}" llamado "${adminAgency.name}" el ${dateNow} `,
        created: dateNow
      }
    } else {
      this.data = {
        method,
        agencyId,
        log: `${this.agent.name} ${this.action} la empresa "${agency.name}" el ${dateNow} `,
        created: dateNow
      }
    }

    this.firestore
      .collection('logs')
      .add(this.data)
      .then(() => {
        console.log('Document successfully written!')
      })
      .catch((error) => {
        console.error('Error writing document: ', error)
      })
  }
}
