export enum StateReservation {
  "PENDING" = "Reserva pendiente de pago", //En estado de pago
  "PAID_OUT" = "Reserva pagada",
  "REFUND_PROCESSING" = "Reembolso en proceso",
  "REFUND_ACCEPTED" = "Reembolsado",
  "REFUND_REJECTED" = "Reembolso rechazado",
  "RE_SCHEDULE" = "Reserva reagendada",
  "CANCEL" = "Reserva cancelada",
  "REQUEST_REFUND" = "Pendiente de reembolso"
}
